import "./datatable.scss";
import {
  DataGrid,
  GridToolbarQuickFilter,
  GridLinkOperator,
} from "@mui/x-data-grid";
import { editorialColumns } from "../../datatablesource";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import { adminType } from "../../constants";

function QuickSearchToolbar() {
  return (
    <Box
      sx={{
        p: 1.5,
        pb: 0,
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <GridToolbarQuickFilter
        quickFilterParser={(searchInput) =>
          searchInput
            .split(",")
            .map((value) => value.trim())
            .filter((value) => value !== "")
        }
      />
      {/* <GridToolbarExport printOptions={{ disableToolbarButton: true }} /> */}
    </Box>
  );
}

const EditorialDatatable = ({ editorials, deleteEditorialModalOpen }) => {
  const user = JSON.parse(localStorage.getItem("user"));

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      disableExport: true,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link
              to={`/editorials/${params.row.editorialId}`}
              style={{ textDecoration: "none" }}
            >
              <div className="viewButton">View</div>
            </Link>
            <Link
              to={`/editorials/update/${params.row.editorialId}`}
              style={{ textDecoration: "none" }}
            >
              <div className="updateButton">Update</div>
            </Link>
            {user.adminType === adminType.superAdmin && (
              <div
                onClick={() => deleteEditorialModalOpen(params.row.editorialId)}
                className="deleteButton"
              >
                Delete
              </div>
            )}
          </div>
        );
      },
    },
    {
      field: "preview",
      headerName: "Preview Link",
      width: 350,
      disableExport: true,
      renderCell: (params) => {
        return (
          <a
            href={`${
              process.env.REACT_APP_SITE_URL
            }/${params.row.editorialType.type
              .split(" ")
              .join("")
              .toLowerCase()}/${params.row.editorialId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {`${process.env.REACT_APP_SITE_URL}/${params.row.editorialType.type
              .split(" ")
              .join("")
              .toLowerCase()}/${params.row.editorialId}`}
          </a>
        );
      },
    },
  ];
  return (
    <>
      <div className="datatable">
        <div className="datatableTitle">
          Editorials
          <Link to="/editorials/new" className="link">
            Add New
          </Link>
        </div>
        <DataGrid
          initialState={{
            filter: {
              filterModel: {
                items: [],
                quickFilterLogicOperator: GridLinkOperator.Or,
              },
            },
          }}
          components={{ Toolbar: QuickSearchToolbar }}
          getRowId={(row) => row.editorialId}
          className="datagrid"
          rows={editorials}
          columns={editorialColumns.concat(actionColumn)}
          pageSize={9}
          rowsPerPageOptions={[9]}
          sx={{
            "& .MuiDataGrid-virtualScroller": {
              overflowX: "scroll",
            },
          }}
        />
      </div>
    </>
  );
};

export default EditorialDatatable;
