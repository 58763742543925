import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import NewsLetterSubscriptionDatatable from "../../components/datatables/NewsLetterSubscriptionDatatable";
import "../page.scss";
import { useEffect, useState } from "react";
import Loader from "../../components/loader/Loader";
import { Services } from "../../Services";
import { toast } from "react-toastify";

const NewsLetterPage = () => {
  const [emailData, setEmailData] = useState(null);

  useEffect(() => {
    getNewsLetterEmails();
  }, []);

  const getNewsLetterEmails = async () => {
    try {
      const response = await Services.getNewsLetterEmails();
      setEmailData(response.data);
    } catch (error) {
        toast.error(
            error.response.data.message ? error.response.data.message : "Error!!",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
    }
  };

  return (
    <div className="page">
      <Sidebar />
      <div className="listContainer">
        <Navbar />
        {emailData ? <NewsLetterSubscriptionDatatable emails={emailData} /> : <Loader />}
      </div>
    </div>
  );
};

export default NewsLetterPage;
