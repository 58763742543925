import "../new.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useState, useEffect } from "react";
import { Services } from "../../Services";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import Loader from "../../components/loader/Loader";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import RichTextEditor from "../Event/RichEditor";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxHeight: "80%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  overflowY: "auto",
  p: 4,
};

const UpdateEditorialPage = ({ inputs, title }) => {
  const { editorialId } = useParams();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const [editorialData, setEditorialData] = useState({
    editorialType: "default",
    headLine: "",
    subHeadLine: "",
    date: "",
    description: "",
    squareImage: "",
    mediaLink: "",
    tags: "",
    publish: false,
    showBelowEditorialType: "default",
    para: ""
  });

  const [editorialTypes, setEditorialTypes] = useState(null);

  useEffect(() => {
    getEditorial();
    getAllEditorialTypes();
  }, []);

  const getAllEditorialTypes = async () => {
    try {
      const response = await Services.getAllEditorialTypes();
      setEditorialTypes(response.data);
    } catch (error) {
      toast.error(
        error.response.data.message ? error.response.data.message : "Error!!",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }
  };
  const getEditorial = async () => {
    try {
      const response = await Services.getEditorial(editorialId);
      const tags = response.data.tags.join();
      var date = "";
      if (response.data.date) {
        date = response.data.date.split("T")[0];
      }
      setEditorialData(prev => {
        return {
          ...prev,
          ...response.data,
          tags: tags,
          showBelowEditorialType: response.data.editorialType._id,
          editorialType: response.data.editorialType._id,
          date: date
        }
      });
    } catch (error) {
      toast.error(
        error?.response?.data?.message ? error?.response?.data?.message : "Error!!",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    if (editorialData.editorialType === "default") {
      toast.error("Please select editorial type", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    var date = null;

    if (editorialData.date) {
      date = new Date(editorialData.date).toISOString();
    }

    const formData = new FormData();

    for (let key of Object.keys(editorialData)) {
      formData.set(key, editorialData[key]);
    }

    formData.set("date", date);
    formData.set("tags", editorialData.tags.split(","))


    try {
      const response = await Services.updateEditorial(editorialId, formData);
      toast.success("Editorial Updated", {
        position: toast.POSITION.TOP_RIGHT,
      });

      navigate(`/editorials/${response.data.editorialId}`);
    } catch (error) {
      if (error?.response?.data?.message === "api-400-all-field-mandatory") {
        toast.error("Enter all mandatory fields", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(
          error.response.data.message ? error.response.data.message : "Error!!",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      }
    }
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (files) {
      return setEditorialData((prevValue) => {
        return {
          ...prevValue,
          [name]: files[0],
        };
      });
    }

    setEditorialData((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1>{title}</h1>
        </div>
        {editorialTypes ? (
          <div className="bottom">
            <div className="right">
              <form onSubmit={onSubmit}>
                {inputs.map((input) => (
                  <div className="formInput" key={input.id}>
                    <label>
                      {input.label}
                      {input.required && "*"}
                    </label>
                    {input.name === "description" ? (
                      <RichTextEditor name={input.name} onChange={handleChange} value={editorialData[input.name]} />
                    ) : input.name === "editorialType" || input.name === "showBelowEditorialType" ? (
                      <select
                        value={editorialData[input.name]}
                        name={input.name}
                        // defaultValue={"default"}
                        onChange={handleChange}
                      >
                        <option value={"default"} disabled={true}>
                          Choose an option
                        </option>
                        {editorialTypes.map((type) => {
                          return (
                            <option key={type._id} value={type._id}>
                              {type.type}
                            </option>
                          );
                        })}
                      </select>
                    ) : input.name === "publish" ? (
                      <select
                        value={editorialData[input.name]}
                        name={input.name}
                        onChange={handleChange}
                      >
                        <option value={true}>True</option>
                        <option value={false}>False</option>
                      </select>
                    ) : (
                      input.type === "quill" ? <RichTextEditor name={input.name} onChange={handleChange} value={editorialData[input.name]} /> : (
                        input.type === "file" ? <input
                          type={input.type}
                          name={input.name}
                          placeholder={input.placeholder}
                          onChange={handleChange}
                        /> : <input
                          type={input.type}
                          name={input.name}
                          value={editorialData[input.name]}
                          placeholder={input.placeholder}
                          onChange={handleChange}
                        />
                      )
                    )}
                  </div>
                ))}
                <div className="formInput-multiple">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                    }}
                  >
                    <label>Paragraphs</label>
                  </div>
                  <div style={{ marginTop: "10px" }} className="input-container" onClick={handleOpen}>
                    <EditIcon />Add
                  </div>
                </div>
                <button>Send</button>
              </form>
            </div>
          </div>
        ) : (
          <Loader />
        )}
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            Paragraphs
          </Typography>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              margin: "10px 0 0 10px",
              padding: "10px 0 0 10px",
            }}
          >
            <RichTextEditor name={"para"} value={editorialData.para} onChange={handleChange} />
          </div>
          <Button
            style={{ marginTop: "20px" }}
            color="error"
            variant="contained"
            onClick={handleClose}
          >
            Close
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default UpdateEditorialPage;
