import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import {
  eventInputs,
  userInputs,
  editorialInputs,
  // galleryInputs,
  vohTvInputs,
  vohTvPageInputs,
  meetTeamInputs,
  contactUsInputs,
  miscellaneousInputs,
  aboutUsInputs,
} from "./formSource";
import Login from "./pages/login/Login";
// import { useDispatch } from "react-redux";
// import { userActions } from "./stores/userStore";
import UsersPage from "./pages/User/UsersPage";
import UserPage from "./pages/User/UserPage";
import AddUserPage from "./pages/User/AddUserPage";
import UpdateUserPage from "./pages/User/UpdateUserPage";
import EventsPage from "./pages/Event/EventsPage";
import EventPage from "./pages/Event/EventPage";
import EventModesPage from "./pages/Event/EventModesPage";
import AddEventPage from "./pages/Event/AddEventPage";
import UpdateEventPage from "./pages/Event/UpdateEventPage";
import AdminPage from "./pages/Admin/AdminPage";
import UserTypesPage from "./pages/User/UserTypesPage";
import EditorialsPage from "./pages/Editorial/EditorialsPage";
import EditorialPage from "./pages/Editorial/EditorialPage";
import AddEditorialPage from "./pages/Editorial/AddEditorialPage";
import UpdateEditorialPage from "./pages/Editorial/UpdateEditorialPage";
// import GallerysPage from "./pages/Gallery/GallerysPage";
// import GalleryPage from "./pages/Gallery/GalleryPage";
// import AddGalleryPage from "./pages/Gallery/AddGallery";
// import UpdateGalleryPage from "./pages/Gallery/UpdateGalleryPage";
import Page404 from "./pages/404/Page404";
import VohTvsPage from "./pages/VohTv/VohTvsPage";
import AddVohTvPage from "./pages/VohTv/AddVohTvPage";
import VohTvPage from "./pages/VohTv/VohTvPage";
import UpdateVohTvPage from "./pages/VohTv/UpdateVohTvPage";
import EditorialTypesPage from "./pages/Editorial/EditorialTypesPage";
import VohTvTypesPage from "./pages/VohTv/VohTvTypesPage";
import UpdateVohTvMainPage from "./pages/VohTv/UpdateVohTvMainPage";
import EventTypesPage from "./pages/Event/EventTypesPage";
// import { toast } from "react-toastify";
// import { adminType } from "./constants";
// import { Services } from "./Services";
import MeetTheTeamPage from "./pages/MeetTheTeam/MeetTheTeamPage";
import ContactUsPage from "./pages/ContactUs/ContactUsPage";
import MiscellaneousPage from "./pages/Miscellaneous/Miscellaneous";
import NewsLetterPage from "./pages/NewsLetter/NewsLetterPage";
import AboutUsPage from "./pages/AboutUs/AboutUsPage";
import AboutUs from "./pages/Incubator/AboutUs";
import ContactUs from "./pages/Incubator/ContactUs";
import Partners from "./pages/Incubator/Partners";
import OurIncubators from "./pages/Incubator/OurIncubators";
import MentorsAndAdvisors from "./pages/Incubator/MentorsAndAdvisors";
import Gallery from "./pages/Incubator/Gallery";
import Incubation from "./pages/Incubator/registration/RegistrationForm";
import ApplyIncubation from "./pages/Incubator/registration/AddIncubationForm";
import UpdateIncubationForm from "./pages/Incubator/registration/UpdateIncubationForm";
import ViewIncubationForm from "./pages/Incubator/registration/ViewIncubationForm";
import Details from "./pages/Collaborations/AllCollaborations";
import UpdateDetails from "./pages/Collaborations/UpdateDetails";
import ViewDetails from "./pages/Collaborations/ViewDetails";
import CollaborationType from "./pages/Collaborations/CollaborationType";


// import uploadImage from "./helper"
// const CustomToastWithLink = () => (
//   <div>
//     <span>You have new notifications for upcoming events </span>
//     <Link to="/events/notifications">click here</Link>
//   </div>
// );

export default function Routing() {

  // const navigate = useNavigate();
  // const dispatch = useDispatch();

  // useEffect(() => {
  //   const data = JSON.parse(localStorage.getItem("user"));

  //   if (!data) {
  //     navigate("/login");
  //     return; // Ensure the function exits after navigation to prevent further execution
  //   } else {
  //     if (data.adminType !== adminType.dataEntry) {
  //       (async () => {
  //         // const notifications = await Services.getEventNotifications();
  //         // if (notifications.data.length !== 0) {
  //         // Logic change by Hirak. Using above logic I am getting this error - "ERROR Cannot read properties of null (reading 'length')  TypeError: Cannot read properties of null (reading 'length')"

  //         // Checking if notifications.data is not null before checking its length
  //         // if (notifications.data && notifications.data.length !== 0) {
  //         //   toast.info(CustomToastWithLink);
  //         // }
  //       })();
  //     }
  //     dispatch({
  //       type: userActions.login.type,
  //       payload: {
  //         emailId: data.emailId,
  //         userId: data.userId,
  //         adminType: data.adminType,
  //       },
  //     });
  //   }
  // }, [dispatch, navigate]); // Included 'dispatch' and 'navigate' in the dependency array

  return (
    <Routes>
      <Route path="/">
        <Route index element={<Navigate to="users" />} />
        <Route path="login" element={<Login />} />
        <Route path="admin">
          <Route index element={<AdminPage />} />
        </Route>
        <Route path="users">
          <Route index element={<UsersPage />} />
          <Route path=":userId" element={<UserPage />} />
          <Route path="usertypes" element={<UserTypesPage />} />
          <Route
            path="new"
            element={<AddUserPage inputs={userInputs} title="Add New User" />}
          />
          <Route
            path="update/:userId"
            element={<UpdateUserPage inputs={userInputs} title="Update User" />}
          />
        </Route>

        <Route path="events">
          <Route index element={<EventsPage />} />
          <Route path="modes" element={<EventModesPage />} />
          <Route path="types" element={<EventTypesPage />} />
          <Route path=":eventId" element={<EventPage />} />
          <Route
            path="new"
            element={
              <AddEventPage inputs={eventInputs} title="Add New Event" />
            }
          />
          <Route
            path="update/:eventId"
            element={
              <UpdateEventPage inputs={eventInputs} title="Update Event" />
            }
          />
        </Route>
        <Route path="editorials">
          {/* <Route index element={<AboutUs />} /> */}
          <Route index element={<EditorialsPage />} />
          <Route path="types" element={<EditorialTypesPage />} />
          <Route path=":editorialId" element={<EditorialPage />} />
          <Route
            path="new"
            element={
              <AddEditorialPage
                inputs={editorialInputs}
                title="Add New Editorial"
              />
            }
          />
          <Route
            path="update/:editorialId"
            element={
              <UpdateEditorialPage
                inputs={editorialInputs}
                title="Update Editorial"
              />
            }
          />
        </Route>
        <Route path="vohtvs">
          <Route index element={<VohTvsPage />} />
          <Route path="types" element={<VohTvTypesPage />} />
          {/* <Route path="page/get" element={<VohTvMainPages />} /> */}
          <Route
            path="page/update/:vohTvTypeId"
            element={
              <UpdateVohTvMainPage
                inputs={vohTvPageInputs}
                title="Update Voh Tv Main Page"
              />
            }
          />
          <Route path=":vohTvId" element={<VohTvPage />} />
          <Route
            path="new"
            element={
              <AddVohTvPage inputs={vohTvInputs} title="Add New Voh Tv" />
            }
          />
          <Route
            path="update/:vohTvId"
            element={
              <UpdateVohTvPage inputs={vohTvInputs} title="Update Voh Tv" />
            }
          />
        </Route>
        {/* <Route path="gallerys">
          <Route index element={<GallerysPage />} />
          <Route path=":galleryId" element={<GalleryPage />} />
          <Route
            path="new"
            element={
              <AddGalleryPage inputs={galleryInputs} title="Add New Gallery" />
            }
          />
          <Route
            path="update/:galleryId"
            element={
              <UpdateGalleryPage
                inputs={galleryInputs}
                title="Update Gallery"
              />
            }
          />
        </Route> */}
        <Route path="meet-the-team">
          <Route
            index
            element={
              <MeetTheTeamPage inputs={meetTeamInputs} title="Meet The Team" />
            }
          />
        </Route>
        <Route path="contact-us">
          <Route
            index
            element={
              <ContactUsPage inputs={contactUsInputs} title="Contact Us" />
            }
          />
        </Route>
        <Route path="miscellaneous">
          <Route
            index
            element={
              <MiscellaneousPage
                inputs={miscellaneousInputs}
                title="Miscellaneous"
              />
            }
          />
        </Route>
        <Route path="newsletter">
          <Route index element={<NewsLetterPage />} />
        </Route>
        <Route path="incubator">
          <Route index element={<AboutUs />} />
          <Route path="contactUs" element={<ContactUs />} />
          <Route path="partners" element={<Partners />} />
          <Route path="our-incubators" element={<OurIncubators />} />
          <Route path="mentors-and-advisors" element={<MentorsAndAdvisors />} />
          <Route path="gallery" element={<Gallery />} />
          <Route path="registration">
            <Route index element={<Incubation />} />
            <Route path="new" element={<ApplyIncubation />} />
            <Route
              path="edit/:incubationId"
              element={<UpdateIncubationForm />}
            />
            <Route path="view/:incubationId" element={<ViewIncubationForm />} />
          </Route>
        </Route>
        <Route path="about-us">
          <Route
            index
            element={<AboutUsPage inputs={aboutUsInputs} title="About Us" />}
          />
        </Route>

        <Route path="collaborations">
          <Route index element={<Details />} />
          <Route path=":collaborationId" element={<ViewDetails />} />
          <Route path="update/:collaborationId" element={<UpdateDetails title="Update Editorial" />} />
          <Route path="collaborationTypes" element={<CollaborationType />} />
        </Route>

        <Route path="*" element={<Page404 />} />
      </Route>
    </Routes>
  );
}
