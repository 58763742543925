import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import UserDatatable from "../../components/datatables/UserDatatable";
import "../page.scss";
import { useEffect, useState } from "react";
import Loader from "../../components/loader/Loader";
import { Services } from "../../Services";
import { toast } from "react-toastify";

const UsersPage = () => {
  const [usersData, setUsersData] = useState(null);

  useEffect(() => {
    getUser();
  }, []);

  const getUser = async () => {
    try {
      const response = await Services.getAllUsers();  
      setUsersData(response.data);
    } catch (error) {
      toast.error(
        error.response.data.message ? error.response.data.message : "Error while fetching users",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }
  };

  return (
    <div className="page">
      <Sidebar />
      <div className="listContainer">
        <Navbar />
        {usersData ? <UserDatatable users={usersData} /> : <Loader />}
      </div>
    </div>
  );
};

export default UsersPage;
