import "./datatable.scss";
import {
  DataGrid,
  GridToolbarQuickFilter,
  GridLinkOperator,
  GridToolbarExport
} from '@mui/x-data-grid';
import { eventColumns } from "../../datatablesource";
import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import { adminType } from "../../constants";

function QuickSearchToolbar() {
  const user = JSON.parse(localStorage.getItem("user"));

  return (
    <Box
      sx={{
        p: 1.5,
        pb: 0,
        display: "flex",
        justifyContent: "space-between"
      }}
    >
      <GridToolbarQuickFilter
        quickFilterParser={(searchInput) =>
          searchInput
            .split(',')
            .map((value) => value.trim())
            .filter((value) => value !== '')
        }
      />
      {user.adminType === adminType.superAdmin && <GridToolbarExport printOptions={{ disableToolbarButton: true }} csvOptions={{ allColumns: true }} />}
    </Box>
  );
}

const EventDatatable = ({ events }) => {

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      disableExport: true,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link
              to={`/events/${params.row.eventId}`}
              style={{ textDecoration: "none" }}
            >
              <div className="viewButton">View</div>
            </Link>
            <Link
              to={`/events/update/${params.row.eventId}`}
              style={{ textDecoration: "none" }}
            >
              <div className="deleteButton">Update</div>
            </Link>
          </div>
        );
      },
    },
    {
      field: "preview",
      headerName: "Preview Link",
      width: 350,
      disableExport: true,
      renderCell: (params) => {
        return (
          <a href={`${process.env.REACT_APP_SITE_URL}/events/${params.row.templateNo}?eventId=${params.row.eventId}`} target="_blank" rel="noopener noreferrer">
            {`${process.env.REACT_APP_SITE_URL}/events/${params.row.templateNo}?eventId=${params.row.eventId}`}
          </a>
        );
      },
    },
  ];
  return (
    <div className="datatable">
      <div className="datatableTitle">
        Events
        <Link to="/events/new" className="link">
          Add New
        </Link>
      </div>
      <DataGrid
        initialState={{
          filter: {
            filterModel: {
              items: [],
              quickFilterLogicOperator: GridLinkOperator.Or,
            },
          },
        }}
        components={{ Toolbar: QuickSearchToolbar }}
        // getRowId={(row) => row._id}
        getRowId={(row) => row.eventId}
        className="datagrid"
        rows={events}
        columns={eventColumns.concat(actionColumn)}
        pageSize={9}
        rowsPerPageOptions={[9]}
        sx={{
          "& .MuiDataGrid-virtualScroller": {
            overflowX: "scroll",
          },
        }}
      />
    </div>
  );
};

export default EventDatatable;
