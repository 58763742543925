import "../new.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useState, useEffect } from "react";
import { Services } from "../../Services";
import { toast } from "react-toastify";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { OutlinedInput } from "@mui/material";
import { InputLabel } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxHeight: "80%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  overflowY: "auto",
  p: 4,
};

const MeetTheTeamPage = ({ inputs, title }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const [members, setMembers] = useState([]);

  const addMember = () => {
    let list = [...members];
    list.push({
      image: "",
      name: "",
      designation: "",
      linkedinUrl: "",
    });
    setMembers(list);
  };

  const addBetweenMember = (index) => {
    let list = [...members];
    list.splice(++index, 0, {
      image: "",
      name: "",
      designation: "",
      linkedinUrl: "",
    });
    setMembers(list);
  };

  const removeBetweenMember = (index) => {
    if (members.length > 0) {
      let list = [...members];
      list.splice(index, 1);
      setMembers(list);
    }
  };

  const removeMember = () => {
    if (members.length > 0) {
      let list = [...members];
      list.pop();
      setMembers(list);
    }
  };

  const [teamData, setTeamData] = useState({
    bannerImage: "",
    heading: "",
    members: [],
  });

  useEffect(() => {
    getMeetTeam();
  }, []);

  const getMeetTeam = async () => {
    try {
      const response = await Services.getMeetTheTeam();
      if (response.data.length > 0) {
        setTeamData(response.data[0]);
        setMembers(response.data[0].members);
      }
    } catch (error) {
      toast.error(
        error.response.data.message ? error.response.data.message : "Error!!",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await Services.updateMeetTheTeam({
        ...teamData,
        members: members,
      });
      if (response.status === 200) {
        toast.success("Meet the team page updated", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      if (error.response.data.message === "api-400-all-field-mandatory") {
        toast.error("Enter all mandatory fields", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(
          error.response.data.message ? error.response.data.message : "Error!!",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setTeamData((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1>{title}</h1>
        </div>
        <div className="bottom">
          <div className="right">
            <form onSubmit={onSubmit}>
              {inputs.map((input) => (
                <div className="formInput" key={input.id}>
                  <label>
                    {input.label}
                    {input.required && "*"}
                  </label>
                  <input
                    type={input.type}
                    name={input.name}
                    value={teamData[input.name]}
                    placeholder={input.placeholder}
                    onChange={handleChange}
                  />
                </div>
              ))}
              <div className="formInput-multiple">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                  }}
                >
                  <label>Members</label>
                </div>
                <div
                  style={{ marginTop: "10px" }}
                  className="input-container"
                  onClick={handleOpen}
                >
                  <EditIcon />
                  Add
                </div>
              </div>
              <button>Send</button>
            </form>
          </div>
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            Members
            <AddCircleIcon onClick={addMember} />
            <RemoveCircleIcon
              onClick={removeMember}
              style={{ color: members.length > 0 ? "black" : "grey" }}
            />
          </Typography>
          {members.map((ele, index) => {
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  margin: "10px 0 0 10px",
                  padding: "10px 0 0 10px",
                }}
              >
                <RemoveCircleIcon onClick={() => removeBetweenMember(index)} />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "900px",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "40%",
                    }}
                  >
                    <InputLabel>Image</InputLabel>
                    <OutlinedInput
                      type="text"
                      name="image"
                      value={ele.image}
                      placeholder="image url"
                      onChange={(e) => {
                        let list = [...members];
                        list[index].image = e.target.value;
                        setMembers(list);
                      }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "40%",
                    }}
                  >
                    <InputLabel>Name</InputLabel>
                    <OutlinedInput
                      type="text"
                      name="name"
                      value={ele.name}
                      placeholder="name"
                      onChange={(e) => {
                        let list = [...members];
                        list[index].name = e.target.value;
                        setMembers(list);
                      }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "900px",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "40%",
                    }}
                  >
                    <InputLabel>Designation</InputLabel>
                    <OutlinedInput
                      type="text"
                      name="designation"
                      value={ele.designation}
                      placeholder="designation"
                      onChange={(e) => {
                        let list = [...members];
                        list[index].designation = e.target.value;
                        setMembers(list);
                      }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "40%",
                    }}
                  >
                    <InputLabel>Linkedin Url</InputLabel>
                    <OutlinedInput
                      type="text"
                      name="linkedinUrl"
                      value={ele.linkedinUrl}
                      placeholder="linkedinUrl"
                      onChange={(e) => {
                        let list = [...members];
                        list[index].linkedinUrl = e.target.value;
                        setMembers(list);
                      }}
                    />
                  </div>
                </div>
                <AddCircleIcon onClick={() => addBetweenMember(index)} />
                <hr style={{ marginTop: "20px" }} />
              </div>
            );
          })}{" "}
          <Button
            style={{ marginTop: "20px" }}
            color="error"
            variant="contained"
            onClick={handleClose}
          >
            Close
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default MeetTheTeamPage;
