import "../singlepage.scss";
import { useParams } from "react-router-dom";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useEffect } from "react";
import { Services } from "../../Services";
import { useState } from "react";
import Loader from "../../components/loader/Loader";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

const VohTvPage = () => {
  const { vohTvId } = useParams();
  const [vohTvData, setVohTvData] = useState(null);

  useEffect(() => {
    const getVohTv = async () => {
      try {
        const response = await Services.getVohTv(vohTvId);
        // console.log(response.data);
        setVohTvData(response.data);
        toast.success("Voh Tv details fetched successfully", {
          position: toast.POSITION.TOP_RIGHT,
        })
      } catch (error) {
        toast.error(
          error.response.data.message ? error.response.data.message : "Error!!",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      }
    };
  
    getVohTv();
  }, [vohTvId]);

  return (
    <div className="single">
      <Sidebar />
      {vohTvData ? (
        <div className="singleContainer">
          <Navbar />
          <div className="top">
            <div className="left">
              <Link
                to={`/vohtvs/update/${vohTvId}`}
                style={{ textDecoration: "none" }}
              >
                <div className="editButton">Edit</div>
              </Link>
              <h1 className="title">Information</h1>
              <div className="item">
                <div className="details">
                  <h1 className="itemTitle">{vohTvData.headLine}</h1>
                  <div className="detailItem">
                    <span className="itemKey">Voh Tv Id:</span>
                    <span className="itemValue">{vohTvData.vohTvId}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Voh TvType:</span>
                    <span className="itemValue">
                      {vohTvData.vohTvType.type}
                    </span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">End Date:</span>
                    <span className="itemValue">
                      {vohTvData.vohTvDate &&
                        new Date(vohTvData.vohTvDate).toLocaleDateString(
                          "en-GB",
                          {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          }
                        )}
                    </span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Start Time:</span>
                    <span className="itemValue">
                      {vohTvData.vohTvTime &&
                        new Date(vohTvData.vohTvTime).toLocaleTimeString()}
                    </span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">HeadLine:</span>
                    <span className="itemValue">{vohTvData.headLine}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Description:</span>
                    <span className="itemValue">{vohTvData.description}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Media Link:</span>
                    <span className="itemValue">
                      <a
                        href={vohTvData.mediaLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {vohTvData.mediaLink}
                      </a>
                    </span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Embedded Link:</span>
                    <span className="itemValue">
                      <a
                        href={vohTvData.embeddedLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {vohTvData.embeddedLink}
                      </a>
                    </span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Banner Image Link:</span>
                    <span className="itemValue">
                      <a
                        href={vohTvData.bannerImageLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {vohTvData.bannerImageLink}
                      </a>
                    </span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Banner Heading:</span>
                    <span className="itemValue">{vohTvData.bannerHeading}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Banner Para:</span>
                    <span className="itemValue">{vohTvData.bannerPara}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Content Heading:</span>
                    <span className="itemValue">
                      {vohTvData.contentHeading}
                    </span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Publish:</span>
                    <span className="itemValue">
                      {vohTvData.publish ? "True" : "False"}
                    </span>
                  </div>
                  {/* <div className="detailItem">
                      <span className="itemKey">Show Below Voh Tv Type:</span>
                      <span className="itemValue">
                        {vohTvData.showBelowVohTvType.type}
                      </span>
                    </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default VohTvPage;
