import React from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import VohTvTypeDatatable from "../../components/datatables/VohTvTypeDatatable";
import "../page.scss";
import { useEffect, useState } from "react";
import Loader from "../../components/loader/Loader";
import { Services } from "../../Services";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { OutlinedInput } from "@mui/material";
import { toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const VohTvTypesPage = () => {
  const [typesData, setTypesData] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [type, setType] = useState({
    id: "",
    type: "",
  });
  const [error, setError] = useState(false);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    getAllVohTvTypes();
  }, [open]);

  const updateTagOpen = (id, type) => {
    setError(false);
    setType({
      id,
      type,
    });
    setOpen(true);
  };

  const addTagOpen = () => {
    setError(false);
    setType({
      id: "",
      type: "",
    });
    setOpen(true);
  };

  const getAllVohTvTypes = async () => {
    try {
      const response = await Services.getAllVohTvTypes();
      setTypesData(response.data);
      // toast.success("Fetched all Voh TV types successfully!", {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
    } catch (error) {
      toast.error(
        error.response.data.message ? error.response.data.message : "Error!!",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }
  };

  const addVohTvType = async (tag) => {
    if (tag.trim() === "") {
      return;
    }
    try {
      const response = await Services.addVohTvType({ type: tag.trim() });
      handleClose();
      getAllVohTvTypes();
      if (response.status === 200) {
        toast.success("Voh TV type added successfully!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      toast.error(
        error.response.data.message ? error.response.data.message : "Error!!",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }
  };

  const updateVohTvType = async (id, tag) => {
    if (tag.trim() === "") {
      return;
    }
    try {
      const response = await Services.updateVohTvType(id, { type: tag.trim() });
      handleClose();
      getAllVohTvTypes();
      if (response.status === 200) {
        toast.success(`Voh TV type with ID: ${id} updated successfully!`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      toast.error(
        error.response.data.message ? error.response.data.message : "Error!!",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }
  };

  const deleteVohTvType = async (id) => {
    if (!id) return;
    try {
      const response = await Services.deleteVohTvType(id);
      if (response.status === 200) {
        toast.success(`Type with ID: ${id} deleted successfully!`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      getAllVohTvTypes(); // Refresh the list after deletion
    } catch (error) {
      toast.error(
        error.response.data.message ? error.response.data.message : "Error!!",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }
  };

  return (
    <div className="page">
      <Sidebar />
      <div className="listContainer">
        <Navbar />
        {typesData ? (
          <VohTvTypeDatatable
            types={typesData}
            updateTagOpen={updateTagOpen}
            addTagOpen={addTagOpen}
            deleteVohTvType={deleteVohTvType}
          />
        ) : (
          <Loader />
        )}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {type.id ? "Update Type " + type.id : "Add Type"}
            </Typography>
            <OutlinedInput
              value={type.type}
              name="type"
              error={error}
              required={true}
              type="text"
              onChange={(e) => {
                if (e.target.value === "") {
                  setError(true);
                } else if (error) {
                  setError(false);
                }
                setType((prevValue) => {
                  return { ...prevValue, [e.target.name]: e.target.value };
                });
              }}
            />
            <Button
              style={{ marginLeft: "20px" }}
              onClick={() =>
                type.id
                  ? updateVohTvType(type.id, type.type)
                  : addVohTvType(type.type)
              }
            >
              {type.id ? "Update" : "Add"}
            </Button>
          </Box>
        </Modal>
      </div>
    </div>
  );
};

export default VohTvTypesPage;
