export const userInputs = [
  {
    id: 1,
    label: "Salutation",
    name: "salutation",
    type: "text",
    placeholder: "Mr/Ms",
    required: "true",
  },
  {
    id: 2,
    label: "First Name",
    name: "firstName",
    type: "text",
    placeholder: "firstName",
    required: "true",
  },
  {
    id: 3,
    label: "Middle Name",
    name: "middleName",
    type: "text",
    placeholder: "middleName",
  },
  {
    id: 4,
    label: "Last Name",
    name: "lastName",
    type: "text",
    placeholder: "lastName",
    required: "true",
  },
  {
    id: 5,
    label: "Email",
    name: "emailId",
    type: "email",
    placeholder: "xyz@gmail.com",
    required: "true",
  },
  {
    id: 6,
    label: "Phone Number(10 digits)",
    name: "phoneNumber",
    type: "number",
    placeholder: "98XXXXXX23",
    required: "true",
  },
  {
    id: 7,
    label: "Gender",
    name: "gender",
    type: "text",
    placeholder: "Female",
    required: "true",
  },
  {
    id: 8,
    label: "Age",
    name: "age",
    type: "number",
    placeholder: "30",
  },
  {
    id: 9,
    label: "DOB",
    name: "dateOfBirth",
    type: "date",
    placeholder: "1/1/1990",
  },
  {
    id: 10,
    label: "Designation",
    name: "designation",
    type: "text",
    placeholder: "Manager",
    required: "true",
  },
  {
    id: 11,
    label: "Organization",
    name: "organization",
    type: "text",
    placeholder: "VOH",
    required: "true",
  },
  {
    id: 12,
    label: "Official Email Id",
    name: "officialEmailId",
    type: "email",
    placeholder: "xyz@company.com",
    required: "true",
  },
  {
    id: 13,
    label: "mobile Number 1(10 digits)",
    name: "mobileNumber1",
    type: "number",
    placeholder: "98XXXXXX23",
  },
  {
    id: 14,
    label: "Landline Number 1",
    name: "landlineNumber1",
    type: "number",
    placeholder: "0112XXXXXX9",
  },
  {
    id: 15,
    label: "Country",
    name: "country",
    type: "text",
    placeholder: "India",
  },
  {
    id: 16,
    label: "State",
    name: "state",
    type: "text",
    placeholder: "Delhi",
  },
  {
    id: 17,
    label: "City",
    name: "city",
    type: "text",
    placeholder: "New Delhi",
    required: "true",
  },
  {
    id: 18,
    label: "Address",
    name: "address",
    type: "text",
    placeholder: "address",
  },
  {
    id: 19,
    label: "Previous Organization",
    name: "previousOrganization",
    type: "text",
    placeholder: "VOH",
  },
  {
    id: 20,
    label: "Flag",
    name: "flag",
    type: "text",
    required: "true",
  },
  {
    id: 21,
    label: "Active User Id",
    name: "activeUserId",
    type: "text",
    placeholder: "enter user id",
  },
  {
    id: 22,
    label: "User Profile",
    name: "userProfile",
    type: "text",
    placeholder: "Summary of user",
  },
  {
    id: 23,
    label: "Account Manager",
    name: "accountManager",
    type: "text",
    placeholder: "enter user id",
  },
  {
    id: 24,
    label: "Remarks",
    name: "remarks",
    type: "text",
    placeholder: "remarks",
  },
];

export const eventInputs = [
  {
    id: 1,
    label: "Event Name(50 Characters)",
    name: "eventName",
    type: "text",
    placeholder: "event",
    required: "true",
  },
  {
    id: 31,
    label: "Event Type",
    name: "eventType",
    type: "text",
    placeholder: "Brand Solution",
    required: "true",
  },
  {
    id: 11,
    label: "Publish",
    name: "publish",
  },
  {
    id: 12,
    label: "Pinned",
    name: "pinned",
  },
  {
    id: 30,
    label: "Template No",
    name: "templateNo",
    type: "Number",
    placeholder: "1",
    required: "true",
  },
  {
    id: 3,
    label: "Mode",
    name: "eventModes",
    type: "text",
    placeholder: "Offline",
    required: "true",
  },
  {
    id: 21,
    label: "Recurring Tag",
    name: "recurringTag",
    type: "text",
    placeholder: "Weekly",
  },
  {
    id: 22,
    label: "Font Family",
    name: "fontFamily",
    type: "text",
    placeholder: "Times New Roman",
  },
  {
    id: 23,
    label: "event Banner File Type",
    name: "eventBannerFileType",
    type: "text",
    placeholder: "image",
  },
  
  {
    id: 24,
    label: "Event Banner Image(1019 x 500)",
    name: "eventBannerImage",
    type: "file",
    placeholder: "image url",
  },
  {
    id: 6,
    label: "Start Date",
    name: "startDate",
    type: "datetime-local",
    placeholder: "1/01/2023",
    required: "true",
  },
  {
    id: 7,
    label: "End Date",
    name: "endDate",
    type: "datetime-local",
    placeholder: "5/01/2023",
    required: "true",
  },
  {
    id: 8,
    label: "Previous Edition Text",
    name: "previousEditionText",
    type: "Cilck here",
    placeholder: "",
    required: "true",
  },
  {
    id: 9,
    label: "Previous Edition Link",
    name: "previousEditionLink",
    type: "string",
    placeholder: "http://voiceofhealthcare.org",
    required: "true",
  },
  {
    id: 10,
    label: "Meta Title",
    name: "metaTitle",
    type: "string",
    placeholder: "Meta Title",
    required: "true",
  },
  {
    id: 11,
    label: "Meta Data",
    name: "metaData",
    type: "string",
    placeholder: "Meta Data",
  },
  // {
  //   id: 21,
  //   label: "Primary Color",
  //   name: "primaryColor",
  //   type: "color",
  //   placeholder: "#ffffff",
  // },
  // {
  //   id: 21,
  //   label: "Secondary Color",
  //   name: "secondaryColor",
  //   type: "color",
  //   placeholder: "#ffffff",
  // },
  // {
  //   id: 21,
  //   label: "Tertiary Color",
  //   name: "tertiaryColor",
  //   type: "color",
  //   placeholder: "#ffffff",
  // },
  // {
  //   id: 2,
  //   label: "Page Description",
  //   name: "pageDescription",
  //   type: "string",
  //   placeholder: "Page Description",
  //   required: "true",
  // },
];

export const editorialInputs = [
  {
    id: 1,
    label: "Editorial Type",
    name: "editorialType",
    type: "text",
    placeholder: "Speaker",
    required: "true",
  },
  {
    id: 2,
    label: "Headline(50 Characters)",
    name: "headLine",
    type: "quill",
    placeholder: "HeadLine",
    required: "true",
  },
  {
    id: 3,
    label: "Sub Head Line(20 Characters)",
    name: "subHeadLine",
    type: "quill",
    placeholder: "subHeadLine",
  },
  {
    id: 4,
    label: "Date",
    name: "date",
    type: "date",
    placeholder: "1/01/2023",
    required: "true",
  },
  {
    id: 5,
    label: "Description(100 Characters)",
    name: "description",
    type: "quill",
    placeholder: "Description",
  },
  {
    id: 9,
    label: "Square Image",
    name: "squareImage",
    type: "file",
    placeholder: "image url",
  },
  {
    id: 6,
    label: "Media Link(3453 x195)",
    name: "mediaLink",
    type: "file",
    placeholder: "www.image.cms",
  },
  {
    id: 7,
    label: "Tags",
    name: "tags",
    type: "text",
    placeholder: "Add tags separated by comma",
  },
  {
    id: 8,
    label: "Publish",
    name: "publish",
  },
  // {
  //   id: 9,
  //   label: "Show Below Editorial Type",
  //   name: "showBelowEditorialType",
  //   type: "text",
  //   required: "true"
  // }
];

export const vohTvPageInputs = [
  {
    id: 1,
    label: "Banner Image",
    name: "bannerImage",
    type: "text",
    placeholder: "image url",
    required: "true",
  },
  {
    id: 2,
    label: "Heading",
    name: "heading",
    type: "text",
    placeholder: "heading",
    required: "true",
  },
  {
    id: 3,
    label: "content",
    name: "content",
    type: "text",
    placeholder: "content",
    required: "true",
  },
];

export const vohTvInputs = [
  {
    id: 1,
    label: "Voh Tv Type",
    name: "vohTvType",
    type: "text",
    placeholder: "Speaker",
    required: "true",
  },
  {
    id: 2,
    label: "Headline(50 Characters)",
    name: "headLine",
    type: "text",
    placeholder: "HeadLine",
    required: "true",
  },
  {
    id: 3,
    label: "Description",
    name: "description",
    type: "text",
    placeholder: "Description",
  },
  // {
  //   id: 13,
  //   label: "Square Image(305x425)",
  //   name: "squareImage",
  //   type: "text",
  //   placeholder: "image url",
  // },
  {
    id: 14,
    label: "Voh Tv Date",
    name: "vohTvDate",
    type: "date",
    placeholder: "5/01/2023",
    required: "true",
  },
  {
    id: 15,
    label: "Voh Tv Time",
    name: "vohTvTime",
    type: "time",
    placeholder: "10:00 AM",
    required: "true",
  },
  // {
  //   id: 10,
  //   label: "Banner Image Link(1230x615)",
  //   name: "bannerImageLink",
  //   type: "text",
  //   placeholder: "image url"
  // },
  // {
  //   id: 4,
  //   label: "Banner Heading(100 Characters)",
  //   name: "bannerHeading",
  //   type: "text",
  //   placeholder: "heading"
  // },
  // {
  //   id: 5,
  //   label: "Banner Para",
  //   name: "bannerPara",
  //   type: "text",
  //   placeholder: "paragraph"
  // },
  {
    id: 6,
    label: "Content Heading",
    name: "contentHeading",
    type: "text",
    placeholder: "heading",
  },
  {
    id: 7,
    label: "Content Body",
    name: "contentBody",
    type: "text",
    placeholder: "body",
  },
  {
    id: 8,
    label: "Media Link",
    name: "mediaLink",
    type: "text",
    placeholder: "www.image.cms",
    required: "true",
  },
  {
    id: 9,
    label: "Embedded Link",
    name: "embeddedLink",
    type: "text",
    placeholder: "www.youtube.com",
    required: "true",
  },
  {
    id: 11,
    label: "Publish",
    name: "publish",
  },
  {
    id: 12,
    label: "Show Below Voh Tv Type",
    name: "showBelowVohTvType",
    type: "text",
    required: "true",
  },
];

export const galleryInputs = [
  {
    id: 1,
    label: "Event Id",
    name: "eventId",
    type: "text",
    placeholder: "enter event id",
    required: "true",
  },
  {
    id: 2,
    label: "User Id(Admin)",
    name: "userId",
    type: "text",
    placeholder: "enter user id",
    required: "true",
  },
  {
    id: 3,
    label: "Files",
    name: "files",
  },
];

export const meetTeamInputs = [
  {
    id: 1,
    label: "Banner Image",
    name: "bannerImage",
    type: "text",
    placeholder: "image url",
    required: "true",
  },
  {
    id: 2,
    label: "Heading",
    name: "heading",
    type: "text",
    placeholder: "heading",
    required: "true",
  },
];

export const contactUsInputs = [
  {
    id: 1,
    label: "Heading",
    name: "heading",
    type: "text",
    placeholder: "heading",
    required: "true",
  },
  {
    id: 2,
    label: "Sub Heading",
    name: "subHeading",
    type: "text",
    placeholder: "subHeading",
    required: "true",
  },
  {
    id: 3,
    label: "Description",
    name: "description",
    type: "text",
    placeholder: "description",
    required: "true",
  },
  {
    id: 4,
    label: "Paragraph",
    name: "paragraph",
    type: "text",
    placeholder: "paragraph",
    required: "true",
  },
];

export const miscellaneousInputs = [
  {
    id: 1,
    label: "Privacy Policy Pdf Link",
    name: "privacyPolicy",
    type: "text",
    placeholder: "Pdf Link",
  },
  {
    id: 2,
    label: "Incubator Section",
    name: "incubatorImage",
    type: "text",
    placeholder: "Image Link",
  },
  {
    id: 3,
    label: "Footer Paragraph",
    name: "footerPara",
    type: "text",
    placeholder: "paragraph",
  },
];

export const advertisementInputs = [
  {
    id: 1,
    label: "ideaLeaderReadMore-horizontalOnTop",
    name: "ideaLeaderReadMorehorizontalOnTop",
    type: "text",
    placeholder: "Link",
  },
  {
    id: 2,
    label: "ideaLeaderReadMore-verticalOnRight",
    name: "ideaLeaderReadMoreverticalOnRight",
    type: "text",
    placeholder: "Link",
  },
  {
    id: 3,
    label: "brandInFocusMainPage-verticalOnLeft",
    name: "brandInFocusMainPageverticalOnLeft",
    type: "text",
    placeholder: "Link",
  },
  {
    id: 4,
    label: "brandInFocusReadMore-verticalOnLeft",
    name: "brandInFocusReadMoreverticalOnLeft",
    type: "text",
    placeholder: "Link",
  },
  {
    id: 5,
    label: "articleAndBlogMainPage-horizontalOnRightBottom1",
    name: "articleAndBlogMainPagehorizontalOnRightBottom1",
    type: "text",
    placeholder: "Link",
  },
  {
    id: 6,
    label: "articleAndBlogMainPage-horizontalOnRightBottom2",
    name: "articleAndBlogMainPagehorizontalOnRightBottom2",
    type: "text",
    placeholder: "Link",
  },
  {
    id: 7,
    label: "articleAndBlogReadMore-horizontalOnTop",
    name: "articleAndBlogReadMorehorizontalOnTop",
    type: "text",
    placeholder: "Link",
  },
  {
    id: 8,
    label: "articleAndBlogReadMore-verticalOnLeftAndRight",
    name: "articleAndBlogReadMoreverticalOnLeftAndRight",
    type: "text",
    placeholder: "Link",
  },
  {
    id: 9,
    label: "newsAndPrReadMore-horizontalOnTop",
    name: "newsAndPrReadMorehorizontalOnTop",
    type: "text",
    placeholder: "Link",
  },
  {
    id: 10,
    label: "newsAndPrReadMore-verticalOnLeftAndRight",
    name: "newsAndPrReadMoreverticalOnLeftAndRight",
    type: "text",
    placeholder: "Link",
  },
];

export const aboutUsInputs = [
  {
    id: 1,
    label: "Banner",
    name: "banner",
    type: "text",
    placeholder: "Banner link",
    required: "true",
  },
  {
    id: 2,
    label: "About Us Heading",
    name: "aboutUsHeading",
    type: "text",
    placeholder: "About Us Heading",
    required: "true",
  },
  {
    id: 3,
    label: "About Us Para",
    name: "aboutUsPara",
    type: "text",
    placeholder: "aboutUsPara",
    required: "true",
  },
  {
    id: 4,
    label: "Mission Content",
    name: "missionContent",
    type: "text",
    placeholder: "Mission Content",
    required: "true",
  },
  {
    id: 5,
    label: "Vision Content",
    name: "visionContent",
    type: "text",
    placeholder: "Vision Content",
    required: "true",
  },
  {
    id: 6,
    label: "Image Section",
    name: "imageSection",
    type: "text",
    placeholder: "Image Section",
    required: "true",
  },
  {
    id: 7,
    label: "Chairman Message",
    name: "chairmanMessage",
    type: "text",
    placeholder: "Chairman Message",
    required: "true",
  },
  {
    id: 8,
    label: "Chairman Image",
    name: "chairmanImage",
    type: "text",
    placeholder: "Chairman Image",
    required: "true",
  },
  {
    id: 7,
    label: "Legal And Financials",
    name: "legalAndFinancials",
    type: "text",
    placeholder: "Legal And Financials link",
    required: "true",
  },
];






