import "../new.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useState } from "react";
import { Services } from "../../Services";
import { toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Loader from "../../components/loader/Loader";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { OutlinedInput } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxHeight: "80%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  overflowY: "auto",
  p: 4,
};

const UpdateVohTvPage = ({ inputs, title }) => {
  const { vohTvId } = useParams();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const [paragraphs, setParagraphs] = useState([]);

  const addParagraph = () => {
    let list = [...paragraphs];
    list.push("");
    setParagraphs(list);
  };
  const removeParagraph = () => {
    if (paragraphs.length > 0) {
      let list = [...paragraphs];
      list.pop();
      setParagraphs(list);
    }
  };

  const [vohTvData, setVohTvData] = useState({
    vohTvType: "",
    headLine: "",
    description: "",
    squareImage: "",
    vohTvDate: "",
    vohTvTime: "",
    mediaLink: "",
    embeddedLink: "",
    bannerImageLink: "",
    bannerHeading: "",
    bannerPara: "",
    contentHeading: "",
    contentBody: "",
    publish: false,
    // showBelowVohTvType: "default"
  });

  const [vohTvTypes, setVohTvTypes] = useState(null);

  useEffect(() => {
    const fetchAllVohTvTypes = async () => {
      try {
        const response = await Services.getAllVohTvTypes();
        setVohTvTypes(response.data);
      } catch (error) {
        toast.error(
          error.response.data.message ? error.response.data.message : "Error!!",
          { position: toast.POSITION.TOP_RIGHT }
        );
      }
    };

    fetchAllVohTvTypes();
  }, []);

  useEffect(() => {
    const fetchVohTv = async () => {
      try {
        const response = await Services.getVohTv(vohTvId);
        const { vohTvDate, vohTvTime, ...rest } = response.data;
        const formattedVohTvDate = vohTvDate ? vohTvDate.split("T")[0] : "";
        const formattedVohTvTime = vohTvTime
          ? new Date(vohTvTime).toTimeString().split(":").slice(0, 2).join(":")
          : "";
        setVohTvData({ ...rest, vohTvType: rest.vohTvType._id, vohTvDate: formattedVohTvDate, vohTvTime: formattedVohTvTime });
        setParagraphs(rest.contentBody);
      } catch (error) {
        toast.error(
          error.response.data.message ? error.response.data.message : "Error!!",
          { position: toast.POSITION.TOP_RIGHT }
        );
      }
    };

    fetchVohTv();
  }, [vohTvId]);

  const createTime = (data) => {
    const time = data.split(":");
    const currentDate = new Date();

    var newDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate(),
      time[0],
      time[1]
    );

    return newDate.toISOString();
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    if (vohTvData.vohTvType === "default") {
      toast.error("Please select voh tv type", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (!vohTvData.vohTvDate || !vohTvData.vohTvTime) {
      toast.error("Enter all mandatory fields", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    const vohTvDate = new Date(vohTvData.vohTvDate).toISOString();
    const vohTvTime = createTime(vohTvData.vohTvTime);

    try {
      const response = await Services.updateVohTv(vohTvId, {
        ...vohTvData,
        vohTvDate: vohTvDate,
        vohTvTime: vohTvTime,
        contentBody: paragraphs,
      });

      toast.success("Voh Tv Updated", {
        position: toast.POSITION.TOP_RIGHT,
      });

      navigate(`/vohtvs/${response.data.vohTvId}`);
    } catch (error) {
      if (error.response.data.message === "api-400-all-field-mandatory") {
        toast.error("Enter all mandatory fields", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(
          error.response.data.message ? error.response.data.message : "Error!!",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setVohTvData((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1>{title}</h1>
        </div>
        {vohTvTypes ? (
          <div className="bottom">
            <div className="right">
              <form onSubmit={onSubmit}>
                {inputs.map((input) => (
                  <div className="formInput" key={input.id}>
                    <label>
                      {input.label}
                      {input.required && "*"}
                    </label>
                    {input.name === "description" ||
                    input.name === "bannerPara" ? (
                      <textarea
                        name={input.name}
                        placeholder={input.placeholder}
                        value={vohTvData[input.name]}
                        onChange={handleChange}
                        rows="5"
                        cols="33"
                      ></textarea>
                    ) : input.name === "vohTvType" ? (
                      <select
                        value={vohTvData[input.name]}
                        name={input.name}
                        // defaultValue={"default"}
                        onChange={handleChange}
                      >
                        <option value={"default"} disabled={true}>
                          Choose an option
                        </option>
                        {vohTvTypes.map((type) => {
                          return (
                            <option key={type._id} value={type._id}>
                              {type.type}
                            </option>
                          );
                        })}
                      </select>
                    ) : input.name === "publish" ? (
                      <select
                        value={vohTvData[input.name]}
                        name={input.name}
                        onChange={handleChange}
                      >
                        <option value={true}>True</option>
                        <option value={false}>False</option>
                      </select>
                    ) : input.name === "contentBody" ? (
                      <div
                        style={{
                          marginTop: "10px",
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                        }}
                        className="input-container"
                        onClick={handleOpen}
                      >
                        <EditIcon />
                        Add
                      </div>
                    ) : (
                      <input
                        type={input.type}
                        name={input.name}
                        value={vohTvData[input.name]}
                        placeholder={input.placeholder}
                        onChange={handleChange}
                      />
                    )}
                  </div>
                ))}
                <button>Send</button>
              </form>
            </div>
          </div>
        ) : (
          <Loader />
        )}
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            Paragraphs
            <AddCircleIcon onClick={addParagraph} />
            <RemoveCircleIcon
              onClick={removeParagraph}
              style={{ color: paragraphs.length > 0 ? "black" : "grey" }}
            />
          </Typography>
          {paragraphs.map((ele, index) => {
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  margin: "10px 0 0 10px",
                  padding: "10px 0 0 10px",
                }}
              >
                <OutlinedInput
                  style={{ width: "100%" }}
                  type="text"
                  value={ele}
                  placeholder="content"
                  onChange={(e) => {
                    let list = [...paragraphs];
                    list[index] = e.target.value;
                    setParagraphs(list);
                  }}
                />
                <hr style={{ marginTop: "20px" }} />
              </div>
            );
          })}{" "}
          <Button
            style={{ marginTop: "20px" }}
            color="error"
            variant="contained"
            onClick={handleClose}
          >
            Close
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default UpdateVohTvPage;
