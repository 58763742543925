import { useState } from "react";
import RichTextEditor from "../Event/RichEditor";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
// import Image from "next/image"
import React from "react";

const MiscellaneousEvent = ({
  miscellaneousDetailsData,
  setMiscellaneousDetailsData,
}) => {
  const addData = () => {
    setMiscellaneousDetailsData({
      groupHeading: "Miscellaneous",
      description: "",
      position: 0,
      publish: false,
      image: "",
    });
  };

  const removeData = () => {
    setMiscellaneousDetailsData(null);
  };

  const [hide, setHide] = useState(true);
  const [hideStatus, setHideStatus] = useState("Show");
  return (
    <>
      <div className="h-20 bg-[#489FC5] my-10 rounded-md">
        <h1 className="flex justify-between items-center text-4xl text-white text-left ml-8 py-3 mr-8">
          <div className="w-[150px] flex justify-between">
            Miscellaneous
            <button
              onClick={(e) => {
                e.preventDefault();
                setHide(!hide);
                setHideStatus(hideStatus === "Show" ? "Hide" : "Show");
              }}
            >
              ({hideStatus})
            </button>
          </div>
          {miscellaneousDetailsData === null ? (
            <AddIcon
              onClick={() => addData()}
              style={{
                fontSize: "48px",
                margin: "0 0 0 40px",
                cursor: "pointer",
              }}
            />
          ) : (
            <RemoveIcon
              onClick={() => removeData()}
              style={{
                fontSize: "48px",
                margin: "0 0 0 40px",
                cursor: "pointer",
              }}
            />
          )}
        </h1>
      </div>
      {miscellaneousDetailsData !== null && (
        <div
          style={{
            visibility: hide ? "hidden" : "visible",
            height: hide ? "0" : "auto",
          }}
        >
          <form action="">
            <div className="flex gap-8 mx-auto w-11/12 mb-4">
              <div className="flex-1">
                <label className="mb-6 text-xl font-medium text-[#489FC5]">
                  Group Heading
                </label>
                <RichTextEditor
                  name={"groupHeading"}
                  value={miscellaneousDetailsData.groupHeading}
                  onChange={(e) => {
                    setMiscellaneousDetailsData((prev) => ({
                      ...prev,
                      groupHeading: e.target.value,
                    }));
                  }}
                />
              </div>
              <div className="flex-1">
                <label className="mb-6 text-xl font-medium text-[#489FC5]">
                  Description
                </label>
                <RichTextEditor
                  name={"description"}
                  value={miscellaneousDetailsData.description}
                  onChange={(e) => {
                    setMiscellaneousDetailsData((prev) => ({
                      ...prev,
                      description: e.target.value,
                    }));
                  }}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full"
                />
              </div>
            </div>

            <div className="flex gap-8 mx-auto w-11/12 mb-4">
              <div className="flex-1">
                <label className="mb-6 text-xl font-medium text-[#489FC5]">
                  Image
                </label>
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => {
                    const file = e.target.files[0];
                    const reader = new FileReader();
                    reader.onloadend = () => {
                      setMiscellaneousDetailsData((prev) => ({
                        ...prev,
                        image: file,
                      }));
                    };
                    if (file) {
                      reader.readAsDataURL(file);
                    }
                  }}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full"
                />
                <img
                  className="max-w-full max-h-full object-cover rounded-lg mt-4 aspect-auto"
                  src={miscellaneousDetailsData.image}
                  alt="Image_Uploaded"
                />
              </div>

              <div className="flex-1">
                <label className="mb-6 text-xl font-medium text-[#489FC5]">
                  Publish
                </label>
                <select
                  value={miscellaneousDetailsData.publish}
                  onChange={(e) => {
                    setMiscellaneousDetailsData((prev) => ({
                      ...prev,
                      publish: e.target.value === "true",
                    }));
                  }}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full"
                >
                  <option value="true">True</option>
                  <option value="false">False</option>
                </select>
              </div>
              <div className="flex-1">
                <label className="mb-6 text-xl font-medium text-[#489FC5]">
                  Position
                </label>
                <input
                  type="number"
                  value={miscellaneousDetailsData.position}
                  onChange={(e) => {
                    setMiscellaneousDetailsData((prev) => ({
                      ...prev,
                      position: e.target.value,
                    }));
                  }}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-full"
                />
              </div>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default MiscellaneousEvent;
