import "../new.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useState, useEffect } from "react";
import { Services } from "../../Services";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";

const UpdateVohTvMainPage = ({ inputs, title }) => {
  const navigate = useNavigate();
  const { vohTvTypeId } = useParams();
  const [pageId,setPageId] = useState();
  const [vohTvData, setVohTvData] = useState({
    bannerImage: "",
    heading: "",
    content: "",
  });

  useEffect(() => {
    const getVohTv = async () => {
      try {
        const response = await Services.getVohTvPage(vohTvTypeId);
        setVohTvData({...response.data});
        setPageId(response.data._id);
      } catch (error) {
        toast.error(
          error.response.data.message ? error.response.data.message : "Error!!",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      }
    };

    getVohTv();
  }, [vohTvTypeId, setPageId, setVohTvData]);

  const onSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await Services.updateVohTvPage(pageId,{
        ...vohTvData,
      });
      if (response.status === 200) {
        toast.success("Voh Tv Page Updated", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
     

      navigate(`/vohtvs/page/get`);

    } catch (error) {
      if (error.response.data.message === "api-400-all-field-mandatory") {
        toast.error("Enter all mandatory fields", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(
          error.response.data.message ? error.response.data.message : "Error!!",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setVohTvData((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1>{title}</h1>
        </div>
        <div className="bottom">
          <div className="right">
            <form onSubmit={onSubmit}>
              {inputs.map((input) => (
                <div className="formInput" key={input.id}>
                  <label>
                    {input.label}
                    {input.required && "*"}
                  </label>
                  {input.name === "content" ? (
                    <textarea
                      name={input.name}
                      placeholder={input.placeholder}
                      value={vohTvData[input.name]}
                      onChange={handleChange}
                      rows="5"
                      cols="33"
                    ></textarea>
                  ) : (
                    <input
                      type={input.type}
                      name={input.name}
                      value={vohTvData[input.name]}
                      placeholder={input.placeholder}
                      onChange={handleChange}
                    />
                  )}
                </div>
              ))}
              <button>Send</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateVohTvMainPage;
