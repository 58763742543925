import { Api } from "./helper";

export const Services = {
  login: async function (data) {
    try {
      const response = await Api.post("/admins/login", data);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  register: async function (data) {
    try {
      const response = await Api.post("/admins/register", data);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  getAllAdmins: async function () {
    try {
      const response = await Api.get(`/admins`);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  deleteAdmin: async function (id) {
    try {
      const response = await Api.delete(`/admins/${id}`);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  getAdmin: async function (id) {
    try {
      const response = await Api.get(`/admins/${id}`);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  getAllUserTypes: async function () {
    try {
      const response = await Api.get(`/users/usertype`);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  addUserType: async function (data) {
    try {
      const response = await Api.post(`/users/usertype`, data);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  updateUserType: async function (id, data) {
    try {
      const response = await Api.put(`/users/usertype/${id}`, data);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  deleteUserType: async function (id) {
    try {
      const response = await Api.delete(`/users/usertype/${id}`);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  getAllUsers: async function () {
    try {
      const response = await Api.get("/users");
      if (response.status === 200) {
        // console.log(response.data);
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  createUser: async function (data) {
    try {
      const response = await Api.post(`/users/create`, data);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  getUser: async function (id) {
    try {
      const response = await Api.get(`/users/${id}`);
      if (response.status === 200) {
        // console.log(response.data);
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  updateUser: async function (id, data) {
    try {
      const response = await Api.put(`/users/${id}`, data);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  getAllEventModes: async function () {
    try {
      const response = await Api.get(`/events/modes`);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  addEventMode: async function (data) {
    try {
      const response = await Api.post(`/events/modes`, data);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  updateEventMode: async function (id, data) {
    try {
      const response = await Api.put(`/events/modes/${id}`, data);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  deleteEventMode: async function (id) {
    try {
      const response = await Api.delete(`/events/modes/${id}`);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  getAllEventTypes: async function () {
    try {
      const response = await Api.get(`/events/eventtypes`);
      console.log("Response Status:", response.status); // Debugging response status
      if (response.status === 200) {
        console.log(response.data);
        return response.data;
      }
    } catch (error) {
      console.error("Error fetching event types:", error); // Debugging error
      throw error;
    }
  },

  addEventType: async function (data) {
    try {
      const response = await Api.post(`/events/type`, data);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  updateEventType: async function (id, data) {
    try {
      const response = await Api.put(`/events/type/${id}`, data);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  deleteEventType: async function (id) {
    try {
      const response = await Api.delete(`/events/type/${id}`);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  getAllEvents: async function () {
    try {
      const response = await Api.get(`/events?limit=1000`);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  getEvent: async function (id) {
    try {
      const response = await Api.get(`/events/${id}`);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  addEvent: async function (data) {
    try {
      const response = await Api.post(`/events`, data, "multipart/form-data");
      if (response.status === 200) {
        console.log(response.data);
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  updateEvent: async function (id, data) {
    try {
      const response = await Api.put(
        `/events/${id}`,
        data,
        "multipart/form-data'"
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  getEventNotifications: async function () {
    try {
      const response = await Api.get(`/events/notifications`);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  addEventRegistrationType: async function (data) {
    try {
      const response = await Api.post(`/eventRegistrations/type`, data);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  getAllEventRegistrationTypes: async function () {
    try {
      const response = await Api.get(`/eventRegistrations/type`);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  updateEventRegistrationType: async function (id, data) {
    try {
      const response = await Api.put(`/eventRegistrations/type/${id}`, data);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  createEventRegistration: async function (data) {
    try {
      const response = await Api.post(`/eventRegistrations/`, data);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  getAllEventRegistrations: async function (id) {
    try {
      const response = await Api.get(`/eventRegistrations/`);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  getAllEventRegistrationsOfUser: async function (id) {
    try {
      const response = await Api.get(`/eventRegistrations/userId?userId=${id}`);
      if (response.status === 200) {
        // console.log(response.data);
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  getAllEventRegistrationsOfEvent: async function (id) {
    try {
      const response = await Api.get(
        `/eventRegistrations/eventId?eventId=${id}`
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  getAllEditorialTypes: async function () {
    try {
      const response = await Api.get(`/updates/type`);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  addEditorialType: async function (data) {
    try {
      const response = await Api.post(`/updates/type`, data);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  updateEditorialType: async function (id, data) {
    try {
      const response = await Api.put(`/updates/type/${id}`, data);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  deleteEditorialType: async function (id) {
    try {
      const response = await Api.delete(`/updates/type/${id}`);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  getAllEditorials: async function () {
    try {
      const response = await Api.get(`/updates?limit=1000`);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  getEditorial: async function (id) {
    try {
      const response = await Api.get(`/updates/${id}`);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  addEditorial: async function (data) {
    try {
      const response = await Api.post(`/updates`, data, "multipart/form-data");
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  updateEditorial: async function (id, data) {
    try {
      const response = await Api.put(
        `/updates/${id}`,
        data,
        "multipart/form-data"
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  deleteEditorial: async function (id) {
    try {
      const response = await Api.delete(`/updates/${id}`);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  getAllBillers: async function () {
    try {
      const response = await Api.get(`/billers`);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  getBiller: async function (id) {
    try {
      const response = await Api.get(`/billers/${id}`);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  addBiller: async function (data) {
    try {
      const response = await Api.post(`/billers`, data);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  updateBiller: async function (id, data) {
    try {
      const response = await Api.put(`/billers/${id}`, data);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  getAllOpportunity: async function () {
    try {
      const response = await Api.get(`/opportunitys`);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  getOpportunity: async function (id) {
    try {
      const response = await Api.get(`/opportunitys/${id}`);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  getAllOpportunityOfBiller: async function (id) {
    try {
      const response = await Api.get(`/opportunitys/billerid/${id}`);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  getAllOpportunityOfEvent: async function (id) {
    try {
      const response = await Api.get(`/opportunitys/eventid/${id}`);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  addOpportunity: async function (data) {
    try {
      const response = await Api.post(`/opportunitys`, data);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  updateOpportunity: async function (id, data) {
    try {
      const response = await Api.put(`/opportunitys/${id}`, data);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  getAllGallerys: async function () {
    try {
      const response = await Api.get(`/gallerys?limit=1000`);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  getGallery: async function (id) {
    try {
      const response = await Api.get(`/gallerys/${id}`);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  addGallery: async function (data) {
    try {
      const response = await Api.post(`/gallerys`, data, "multipart/form-data");
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  updateGallery: async function (id, data) {
    try {
      const response = await Api.put(
        `/gallerys/${id}`,
        data,
        "multipart/form-data"
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  deleteGallery: async function (id) {
    try {
      const response = await Api.delete(`/gallerys/${id}`);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  getAllInvoices: async function () {
    try {
      const response = await Api.get(`/invoices`);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  getInvoice: async function (id) {
    try {
      const response = await Api.get(`/invoices/${id}`);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  updateInvoice: async function (id, data) {
    try {
      const response = await Api.put(`/invoices/${id}`, data);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  getAllVohTvTypes: async function () {
    try {
      const response = await Api.get(`/vohtvs/type`);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  addVohTvType: async function (data) {
    try {
      const response = await Api.post(`/vohtvs/type`, data);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  updateVohTvType: async function (id, data) {
    try {
      const response = await Api.put(`/vohtvs/type/${id}`, data);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  deleteVohTvType: async function (id) {
    try {
      const response = await Api.delete(`/vohtvs/type/${id}`);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  getAllVohTvs: async function () {
    try {
      const response = await Api.get(`/vohtvs?limit=1000`);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  getVohTv: async function (id) {
    try {
      const response = await Api.get(`/vohtvs/${id}`);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  addVohTv: async function (data) {
    try {
      const response = await Api.post(`/vohtvs`, data);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  updateVohTv: async function (id, data) {
    try {
      const response = await Api.put(`/vohtvs/${id}`, data);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  deleteVohTv: async function (id) {
    try {
      const response = await Api.delete(`/vohtvs/${id}`);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  getAllVohTvPages: async function () {
    try {
      const response = await Api.get(`/vohtvs/page/get`);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  getVohTvPage: async function (id) {
    try {
      const response = await Api.get(`/vohtvs/page/get/${id}`);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  updateVohTvPage: async function (id, data) {
    try {
      const response = await Api.put(`/vohtvs/page/update/${id}`, data);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  getMeetTheTeam: async function () {
    try {
      const response = await Api.get(`/meetteam`);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  updateMeetTheTeam: async function (data) {
    try {
      const response = await Api.post(`/meetteam`, data);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  getContactUs: async function () {
    try {
      const response = await Api.get(`/contactus`);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  updateContactUs: async function (data) {
    try {
      const response = await Api.post(`/contactus`, data);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  getMiscellaneous: async function () {
    try {
      const response = await Api.get(`/miscellaneous`);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  updateMiscellaneous: async function (data) {
    try {
      const response = await Api.post(`/miscellaneous`, data);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  updatePassword: async function (id, data) {
    try {
      const response = await Api.put(`/admins/password/reset/${id}`, data);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  getNewsLetterEmails: async function () {
    try {
      const response = await Api.get(`/newsletter/emails`);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  getAllBrands: async function () {
    try {
      const response = await Api.get(`/brands`);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  addBrand: async function (data) {
    try {
      const response = await Api.post(`/brands`, data);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  deleteBrand: async function (id) {
    try {
      const response = await Api.delete(`/brands/${id}`);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  getAdvertisement: async function () {
    try {
      const response = await Api.get(`/advertisement`);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  updateAdvertisement: async function (data) {
    try {
      const response = await Api.post(`/advertisement`, data);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  getAboutUs: async function () {
    try {
      const response = await Api.get(`/aboutus`);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  updateAboutUs: async function (data) {
    try {
      const response = await Api.post(`/aboutus`, data);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  getAllCollaborations: async function (page, limit) {
    try {
      const response = await Api.get(
        `/collaborations?page=${page}&limit=${limit}`
      );
      if (response.status === 200) {
        // console.log(response.data);
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  getCollaborationById: async function (id) {
    try {
      const response = await Api.get(`/collaborations?id=${id}`);
      if (response.status === 200) {
        // console.log(response.data);
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  updateCollaborationById: async function (id, data) {
    try {
      const response = await Api.put(`/collaborations/${id}`, data);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  //Collaboration Types
  getAllCollaborationTypes: async function () {
    try {
      const response = await Api.get(`/collaborations/types`);
      console.log("Response Status:", response.status); // Debugging response status
      if (response.status === 200) {
        console.log(response.data);
        return response.data;
      }
    } catch (error) {
      console.error("Error fetching collaborations types:", error); // Debugging error
      throw error;
    }
  },

  addCollaborationType: async function (data) {
    try {
      const response = await Api.post(`/collaborations/types`, data);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  updateCollaborationType: async function (id, data) {
    try {
      const response = await Api.put(`/collaborations/types/${id}`, data);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  deleteCollaborationType: async function (id) {
    try {
      const response = await Api.delete(`/collaborations/types/${id}`);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },
};







