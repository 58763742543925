import "../new.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useEffect, useState } from "react";
import { Services } from "../../Services";
import { toast } from "react-toastify";
import Loader from "../../components/loader/Loader";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

const UpdateUserPage = ({ inputs, title }) => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  // const [oldRemarks, setOldRemarks] = useState("");
  const [userTypesData, setUserTypesData] = useState(null);
  const [types, setTypes] = useState([]);

  const addTypes = () => {
    let list = [...types];
    list.push("default");
    setTypes(list);
  };
  const removeTypes = () => {
    if (types.length > 0) {
      let list = [...types];
      list.pop();
      setTypes(list);
    }
  };

  const [userData, setUserData] = useState({
    emailId: "",
    phoneNumber: "",
    salutation: "",
    firstName: "",
    middleName: "",
    lastName: "",
    gender: "",
    dateOfBirth: "",
    age: "",
    organization: "",
    designation: "",
    officialEmailId: "",
    mobileNumber1: "",
    landlineNumber1: "",
    address: "",
    city: "",
    state: "",
    country: "",
    previousOrganization: "",
    flag: "active",
    activeUserId: "",
    userProfile: "",
    userType: [],
    accountManager: "",
    remarks: "",
    remarksDate: "",
    updatedBy: "",
    profileComplete: false,
  });

  useEffect(() => {
    getUserTypes();
  }, []);

  const getUserTypes = async () => {
    try {
      const response = await Services.getAllUserTypes();
      setUserTypesData(response.data);
    } catch (error) {
      toast.error(
        error.response.data.message ? error.response.data.message : "Error!!",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }
  };

  useEffect(() => {
    const getUser = async () => {
      try {
        const response = await Services.getUser(userId);
        let dob = "";
        if (response.data.dateOfBirth) {
          dob = response.data.dateOfBirth.split("T")[0];
        }
        setUserData({
          ...response.data,
          dateOfBirth: dob,
          userType: response.data.userType._id,
        });
        // setOldRemarks(response.data.remarks);
        let types = [];
        for (var i = 0; i < response.data.userType.length; i++) {
          types.push(response.data.userType[i]._id);
        }
        setTypes(types);
      } catch (error) {
        toast.error(
          error.response.data.message ? error.response.data.message : "Error!!",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      }
    };

    getUser();
  }, [userId]);

  const onSubmit = async (event) => {
    event.preventDefault();

    if (
      !userData.salutation ||
      !userData.firstName ||
      !userData.lastName ||
      !userData.officialEmailId ||
      !userData.organization ||
      !userData.designation ||
      !userData.city
    ) {
      toast.error("Enter all mandatory fields", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (!userData.phoneNumber && !userData.emailId) {
      toast.error("Please Eneter Email or Phone Number", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    const validateEmailRegex = /^\S+@\S+\.\S+$/;

    if (userData.emailId !== "") {
      if (!validateEmailRegex.test(userData.emailId)) {
        toast.error("Please Eneter valid email id", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }
    }

    if (userData.officialEmailId !== "") {
      if (!validateEmailRegex.test(userData.officialEmailId)) {
        toast.error("Please Eneter valid official email id", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }
    }

    if (userData.phoneNumber) {
      if (userData.phoneNumber.length !== 10) {
        toast.error("Please Eneter valid phone number", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }
    }

    if (userData.mobileNumber1) {
      if (userData.mobileNumber1.length !== 10) {
        toast.error("Please Eneter valid mobile number 1", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }
    }

    if (types.length === 0) {
      toast.error(`Please choose one type`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (types.length !== 0) {
      for (var i = 0; i < types.length; i++) {
        if (types[i] === "default") {
          toast.error(`Please select user type of ${i + 1} input`, {
            position: toast.POSITION.TOP_RIGHT,
          });
          return;
        }
      }
    }

    var dob = "";

    if (userData.dateOfBirth) {
      dob = new Date(userData.dateOfBirth).toISOString();
    }

    var remarksDate = "";
    if (userData.remarks) {
      remarksDate = new Date().toISOString();
    }

    try {
      const response = await Services.updateUser(userId, {
        ...userData,
        dateOfBirth: dob,
        updatedBy: user.userId,
        remarksDate: remarksDate,
        userType: types,
      });

      toast.success("User Updated", {
        position: toast.POSITION.TOP_RIGHT,
      });

      navigate(`/users/${response.data.userId}`);
    } catch (error) {
      if (error.response.data.message === "api-400-all-field-mandatory") {
        toast.error("Enter all mandatory fields", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(
          error.response.data.message ? error.response.data.message : "Error!!",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setUserData((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1>{title}</h1>
        </div>
        {userTypesData ? (
          <div className="bottom">
            <div className="right">
              <form onSubmit={onSubmit}>
                {inputs.map((input) => (
                  <div className="formInput" key={input.id}>
                    <label>
                      {input.label}
                      {input.required && "*"}
                    </label>
                    {input.name === "userProfile" ? (
                      <textarea
                        name={input.name}
                        placeholder={input.placeholder}
                        value={userData[input.name]}
                        onChange={handleChange}
                        rows="5"
                        cols="33"
                      ></textarea>
                    ) : input.name === "flag" ? (
                      <select
                        defaultValue={userData[input.name]}
                        name={input.name}
                        onChange={handleChange}
                      >
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                      </select>
                    ) : input.name === "phoneNumber" ||
                      input.name === "mobileNumber1" ||
                      input.name === "age" ? (
                      <input
                        type={input.type}
                        name={input.name}
                        value={userData[input.name]}
                        placeholder={input.placeholder}
                        onChange={handleChange}
                        min="0"
                      />
                    ) : (
                      <input
                        type={input.type}
                        name={input.name}
                        value={userData[input.name]}
                        placeholder={input.placeholder}
                        onChange={handleChange}
                      />
                    )}
                  </div>
                ))}
                <div className="formInput-multiple">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "30px",
                    }}
                  >
                    <label>User Types*</label>
                    <AddCircleIcon onClick={addTypes} />
                    <RemoveCircleIcon
                      onClick={removeTypes}
                      style={{
                        color: types.length > 0 ? "black" : "grey",
                      }}
                    />
                  </div>
                  {types.map((type, index) => {
                    return (
                      <div className="input-container">
                        <select
                          style={{ width: "100%" }}
                          value={type}
                          name="type"
                          // defaultValue={"default"}
                          onChange={(e) => {
                            let list = [...types];
                            list[index] = e.target.value;
                            setTypes(list);
                          }}
                        >
                          <option value={"default"} disabled>
                            Choose an option
                          </option>
                          {userTypesData.map((usertype) => {
                            return (
                              <option key={usertype._id} value={usertype._id}>
                                {usertype.userType}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    );
                  })}{" "}
                </div>
                <button>Send</button>
              </form>
            </div>
          </div>
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
};

export default UpdateUserPage;
