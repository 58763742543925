import "./datatable.scss";
import {
  DataGrid,
  GridToolbarQuickFilter,
  GridLinkOperator,
  GridToolbarExport
} from '@mui/x-data-grid';
import { newsLetterEmailsColumns } from "../../datatablesource";
// import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import { adminType } from "../../constants";

function QuickSearchToolbar() {
  const user = JSON.parse(localStorage.getItem("user"));

  return (
    <Box
      sx={{
        p: 1.5,
        pb: 0,
        display: "flex",
        justifyContent: "space-between"
      }}
    >
      <GridToolbarQuickFilter
        quickFilterParser={(searchInput) =>
          searchInput
            .split(',')
            .map((value) => value.trim())
            .filter((value) => value !== '')
        }
      />
      {user.adminType === adminType.superAdmin && <GridToolbarExport printOptions={{ disableToolbarButton: true }} csvOptions={{ allColumns: true }} />}
    </Box>
  );
}

const NewsLetterSubscriptionDatatable = ({ emails }) => {
  const actionColumn = [
    // {
    //   field: "action",
    //   headerName: "Action",
    //   width: 200,
    //   disableExport: true,
    //   renderCell: (params) => {
    //     return (
    //       <div className="cellAction">
    //         <Link
    //           to={`/invoices/${params.row.invoiceId}`}
    //           style={{ textDecoration: "none" }}
    //         >
    //           <div className="viewButton">View</div>
    //         </Link>
    //         <Link
    //           to={`/invoices/update/${params.row.invoiceId}`}
    //           style={{ textDecoration: "none" }}
    //         >
    //           <div className="deleteButton">Update</div>
    //         </Link>
    //       </div>
    //     );
    //   },
    // },
  ];
  return (
    <div className="datatable">
      <div className="datatableTitle">
        News Letter Subscriptions
        {/* <Link to="/invoices/new" className="link">
          Add New
        </Link> */}
      </div>
      <DataGrid
        initialState={{
          filter: {
            filterModel: {
              items: [],
              quickFilterLogicOperator: GridLinkOperator.Or,
            },
          },
        }}
        components={{ Toolbar: QuickSearchToolbar }}
        getRowId={(row) => row._id}
        className="datagrid"
        rows={emails}
        columns={newsLetterEmailsColumns.concat(actionColumn)}
        pageSize={9}
        rowsPerPageOptions={[9]}
        sx={{
          "& .MuiDataGrid-virtualScroller": {
            overflowX: "scroll",
          },
        }}
      />
    </div>
  );
};

export default NewsLetterSubscriptionDatatable;
