import "../singlepage.scss";
import { useParams } from "react-router-dom";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useCallback, useEffect } from "react";
import { Services } from "../../Services";
import { useState } from "react";
import Loader from "../../components/loader/Loader";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

const EditorialPage = () => {
  const { editorialId } = useParams();
  const [editorialData, setEditorialData] = useState(null);

  const getEditorial = useCallback(async () => {
    try {
      const response = await Services.getEditorial(editorialId);
      // console.log(response.data);
      setEditorialData(response.data);
      toast.success("Editorial fetched successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      toast.error(
        error.response.data.message ? error.response.data.message : "Error!!",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }
  }, [editorialId]);

  useEffect(() => {
    getEditorial();
  }, [editorialId, getEditorial]);

  return (
    <div className="single">
      <Sidebar />
      {editorialData ? (
        <div className="singleContainer">
          <Navbar />
          <div className="top">
            <div className="left">
              <Link
                to={`/editorials/update/${editorialId}`}
                style={{ textDecoration: "none" }}
              >
                <div className="editButton">Edit</div>
              </Link>
              <h1 className="title">Information</h1>
              <div className="item">
                <div className="details">
                  <h1 className="itemTitle">{editorialData.headLine}</h1>
                  <div className="detailItem">
                    <span className="itemKey">Editorial Id:</span>
                    <span className="itemValue">
                      {editorialData.editorialId}
                    </span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Editorial Type:</span>
                    <span className="itemValue">
                      {editorialData.editorialType.type}
                    </span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">HeadLine:</span>
                    <span className="itemValue">{editorialData.headLine}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Sub HeadLine:</span>
                    <span className="itemValue">
                      {editorialData.subHeadLine}
                    </span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Date:</span>
                    <span className="itemValue">
                      {editorialData.date
                        ? new Date(editorialData.date).toLocaleDateString(
                            "en-GB",
                            {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            }
                          )
                        : null}
                    </span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Description:</span>
                    <span className="itemValue">
                      {editorialData.description}
                    </span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Media Link:</span>
                    <span className="itemValue">
                      <a
                        href={editorialData.mediaLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {editorialData.mediaLink}
                      </a>
                    </span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">tags:</span>
                    {editorialData.tags.map((tag, index) => {
                      return (
                        <span key={index} className="itemValue">
                          {tag},
                        </span>
                      );
                    })}
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Publish:</span>
                    <span className="itemValue">
                      {editorialData.publish ? "True" : "False"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default EditorialPage;
