import { useState } from 'react'
import RichTextEditor from '../Event/RichEditor';
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { Typography } from '@mui/material';
// import { MdDelete } from "react-icons/md";
import AddIcon from '@mui/icons-material/Add';
import { toast } from 'react-toastify';
import RemoveIcon from "@mui/icons-material/Remove";

function Awards({ awardDetailsData, setAwardDetailsData }) {
    console.log(awardDetailsData);

    const addData = () => {
        setAwardDetailsData([...awardDetailsData, {
            groupHeading: "Award",
            description: "",
            position: 0,
            publish: false,
            data: [],
        }]);
        toast.success(" New Award group added successfully!", {
            position: toast.POSITION.TOP_RIGHT,
        });
    };

    const removeLastGroup = () => {
        if (awardDetailsData.length > 0) {
            const updatedData = awardDetailsData.slice(0, -1);
            setAwardDetailsData(updatedData);
            toast.success("Last group removed successfully!", {
                position: toast.POSITION.TOP_RIGHT,
            });
        } else {
            toast.warning("No groups to remove!", {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    };

    // const removeData = (index) => {
    //   if (window.confirm("Are you sure you want to delete this section?")) {
    //     setAwardDetailsData(awardDetailsData.filter((_, i) => i !== index));
    //     toast.success("Award group deleted successfully!", {

    //       position: toast.POSITION.TOP_RIGHT,

    //     });
    //   }
    // };

    const handleAddAwards = (index) => {
        const updatedData = [...awardDetailsData];
        updatedData[index].data.push({
            organization: "default",
            categorie: "",
            position: updatedData[index].data.length + 1,
        });
        setAwardDetailsData(updatedData);
    };

    const handleRemoveAwards = (index) => {
        const updatedData = [...awardDetailsData];
        if (updatedData[index].data.length > 0) {
            updatedData[index].data.pop();
            setAwardDetailsData(updatedData);
        }
    };

    const updateAwardsData = (groupIndex, AwardIndex, key, value) => {
        const updatedData = [...awardDetailsData];
        updatedData[groupIndex].data[AwardIndex] = {
            ...updatedData[groupIndex].data[AwardIndex],
            [key]: value,
        };
        setAwardDetailsData(updatedData);
    };

    const [hide, setHide] = useState(true);
    const [hideStatus, setHideStatus] = useState("Show");

    return (
        <>
            <div className="h-20 bg-[#489FC5] my-10 rounded-md">
                <h1 className="flex justify-between items-center text-4xl text-white text-left ml-8 py-3 mr-8">
                    <div className="w-[200px] flex justify-around">
                        Awards
                        <button
                            onClick={(e) => {
                                e.preventDefault();
                                setHide(!hide);
                                setHideStatus(hideStatus === "Show" ? "Hide" : "Show");
                            }}
                        >
                            ({hideStatus})
                        </button>
                    </div>
                    <div>
                        <AddIcon
                            onClick={() => addData()}
                            style={{
                                fontSize: "48px",
                                margin: "0 0 0 40px",
                                cursor: "pointer",
                            }}
                        />
                        {awardDetailsData.length > 0 && (
                            <RemoveIcon
                                onClick={() => removeLastGroup()}
                                style={{
                                    fontSize: "48px",
                                    margin: "0 0 0 20px",
                                    cursor: "pointer",
                                }}
                            />
                        )}
                    </div>
                </h1>
            </div>
            {awardDetailsData.map((awardGroup, groupIndex) => (
                <div key={groupIndex} style={{ visibility: hide ? 'hidden' : 'visible', height: hide ? '0' : 'auto' }} className='mt-1 border-2 border-solid border-[#489FC5] rounded-md'>
                    <div className="flex justify-between mr-4 text-red-600">
                        <span className="mr-2 text-xl text-[#489FC5] mt-2 ml-2">{`Group: ${groupIndex + 1}`}</span>
                        {/* <MdDelete
              onClick={() => removeData(groupIndex)}
              style={{
                fontSize: "35px",
                cursor: "pointer",
                marginTop: "5px"
              }}
            /> */}
                    </div>
                    <form action="">
                        <div className="mx-auto w-11/12  mb-12">
                            <label
                                htmlFor=""
                                className=" mb-6   text-xl  font-medium text-[#489FC5]"
                            >
                                Group Heading
                            </label>
                            <RichTextEditor
                                name={"groupHeading"}
                                value={awardGroup.groupHeading}
                                onChange={(e) => {
                                    const updatedData = [...awardDetailsData];
                                    updatedData[groupIndex].groupHeading = e.target.value;
                                    setAwardDetailsData(updatedData);
                                }}
                            />
                        </div>

                        <div className="mx-auto w-11/12  mb-12">
                            <label
                                htmlFor=""
                                className=" mb-6   text-xl  font-medium text-[#489FC5]"
                            >
                                Description
                            </label>
                            <RichTextEditor
                                name={"description"}
                                value={awardGroup.description}
                                onChange={(e) => {
                                    const updatedData = [...awardDetailsData];
                                    updatedData[groupIndex].description = e.target.value;
                                    setAwardDetailsData(updatedData);
                                }}
                            />
                        </div>

                        <div className="flex gap-8 mx-auto w-11/12 mb-4">
                            <div className="flex-1">
                                <label className=" mb-6   text-xl  font-medium text-[#489FC5]">
                                    Position
                                </label>
                                <input
                                    type="number"
                                    value={awardGroup.position}
                                    onChange={(e) => {
                                        const updatedData = [...awardDetailsData];
                                        updatedData[groupIndex].position = e.target.value;
                                        setAwardDetailsData(updatedData);
                                    }}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block  p-5  w-full    "
                                />
                            </div>
                            <div className=" flex-1 ">
                                <div className=" ">
                                    <label className=" w-8/12 gap-8 ml-16  text-xl  font-medium text-[#489FC5] ">
                                        Publish :
                                        <select
                                            value={awardGroup.publish}
                                            onChange={(e) => {
                                                const updatedData = [...awardDetailsData];
                                                updatedData[groupIndex].publish = e.target.value === 'true';
                                                setAwardDetailsData(updatedData);
                                            }}
                                        >
                                            <option value="true">True</option>
                                            <option value="false">False</option>
                                        </select>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div>
                            <Typography
                                id="modal-modal-title"
                                variant="h6"
                                component="h2"
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    color: "#489FC5",
                                    fontSize: "30px",
                                    margin: "40px 0 0 68px",
                                }}
                            >
                                Awards Data
                                <AddCircleIcon
                                    onClick={() => handleAddAwards(groupIndex)}
                                    style={{ fontSize: "36px", margin: "0 0 0 40px" }}
                                />
                                <RemoveCircleIcon
                                    onClick={() => handleRemoveAwards(groupIndex)}
                                    style={{ fontSize: "36px", margin: "0 0 0 20px" }}
                                />

                            </Typography>
                            <div className="h-0 w-11/12 border-[2px] border-[#489FC5] mx-auto my-10"></div>
                            {awardGroup.data.map((award, awardIndex) => (
                                // console.log(award);
                                <div key={awardIndex}>
                                    <div className=" flex w-11/12 gap-8  my-6 mb-10 mx-auto">
                                        <div className=" flex-1">
                                            <label className=" mb-6  text-xl   font-medium text-[#489FC5]">
                                                Organization
                                            </label>
                                            <RichTextEditor
                                                value={award.organization}
                                                onChange={(e) =>
                                                    updateAwardsData(groupIndex, awardIndex, "organization", e.target.value)
                                                }
                                            />
                                        </div>
                                        <div className=" flex-1">
                                            <label className=" mb-6  text-xl   font-medium text-[#489FC5]">
                                                Categorie
                                            </label>
                                            <RichTextEditor
                                                value={award.categorie}
                                                onChange={(e) =>
                                                    updateAwardsData(groupIndex, awardIndex, "categorie", e.target.value)
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className=" flex w-11/12 gap-8  my-6 mb-10 mx-auto">
                                        <div className=" flex-1">
                                            <label className=" mb-6  text-xl   font-medium text-[#489FC5]">
                                                Position
                                            </label>
                                            <input
                                                type="number"
                                                value={award.position}
                                                onChange={(e) =>
                                                    updateAwardsData(groupIndex, awardIndex, "position", e.target.value)
                                                }
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block  p-5 w-36      "
                                            />
                                        </div>
                                    </div>
                                    <div className="h-0 w-11/12 border-[2px] border-[#489FC5] mx-auto my-10"></div>
                                </div>
                            ))}
                        </div>
                    </form>
                </div>
            ))}
        </>
    );
}

export default Awards;