import React, { useCallback } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useState } from "react";
import { Services } from "../../Services";
import { toast } from "react-toastify";
import Loader from "../../components/loader/Loader";
import { useEffect } from "react";
import "../new.scss";
import RichTextEditor from "./RichEditor";
import KeyTracks from "../NewEvents/KeyTracks";
import Partners from "../NewEvents/Partners";
import WhyParticipate from "../NewEvents/WhyParticipate";
import Agenda from "../NewEvents/Agenda";
import Speaker from "../NewEvents/Speaker";
import Registration from "../NewEvents/Registration";
import Faqs from "../NewEvents/Faqs";
import PreviousEvent from "../NewEvents/PreviousEvent";
import EventGallery from "../NewEvents/EventGallery";
import ContactUs from "../NewEvents/ContactUs";
import Venue from "../NewEvents/Venue";
import Overview from "../NewEvents/Overview";
import Awards from "../NewEvents/Awards";
// import KeyHighlight from "../NewEvents/KeyHighlight";
import DiscussionPoints from "../NewEvents/DiscussionPoints";
import MiscellaneousEvent from "../NewEvents/MiscellaneousEvent";
import { useParams } from "react-router-dom";

const UpdateEventPage = ({ inputs, title }) => {
  // Import necessary hooks and services
  const { eventId } = useParams();

  // Initial state for event data
  const [eventData, setEventData] = useState({
    eventName: "",
    eventDescription: "",
    eventModes: "default",
    eventType: "default",
    startDate: "",
    endDate: "",
    publish: false,
    pinned: false,
    recurringTag: "None",
    eventBannerFileType: "image",
    primaryColor: "#FF0000",
    secondaryColor: "#FF0000",
    tertiaryColor: "#FF0000",
    fourthColor: "#E62177",
    fifthColor: "#2A3273",
    sixthColor: "#ebbcd0",
    fontFamily: "",
    metaTitle: "",
    metaData: "",
    eventBannerImage: "",
    templateNo: 1,
  });
  console.log(eventData);

  // State for event modes and types
  const [eventModes, setEventModes] = useState(null);
  const [eventTypes, setEventTypes] = useState(null);

  // Initial states for various event details
  const [eventPreviousData, SetEventPreviousData] = useState([]);
  const [eventGalleryData, SetEventGalleryData] = useState([]);
  const [keyTracksData, SetKeyTracksData] = useState([]);
  const [agendaDetailsData, setAgendaDetailsData] = useState([]);
  const [contactUsDetailsData, setContactUsDetailsData] = useState([]);
  const [discussionPointDetailsData, setDiscussionPointDetailsData] = useState([]);
  const [faqsDetailsData, setFaqsDetailsData] = useState([]);
  // const [keyHighlightDetailsData, setKeyHighlightDetailsData] = useState(null);
  const [overviewDetailsData, setOverviewDetailsData] = useState([]);
  const [partnersDetailsData, setPartnersDetailsData] = useState([]);
  const [registrationDetailsData, setRegistrationDetailsData] = useState([]);
  const [speakerDetailsData, setSpeakerDetailsData] = useState([]);
  const [whyParticipateDetailsData, setWhyParticipateDetailsData] = useState([]);
  const [awardDetailsData, setAwardDetailsData] = useState([]);
  const [venueDetailsData, setVenueDetailsData] = useState(null);
  const [miscellaneousDetailsData, setMiscellaneousDetailsData] = useState(null);

  // Function to fetch all event types
  const getEventTypes = async () => {
    try {
      const response = await Services.getAllEventTypes();
      setEventTypes(response.data);
    } catch (error) {
      toast.error(
        error.response.data.message ? error.response.data.message : "Error!!",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }
  };

  // Function to fetch all event modes
  const getEventModes = async () => {
    try {
      const response = await Services.getAllEventModes();
      setEventModes(response.data);
    } catch (error) {
      toast.error(
        error.response.data.message ? error.response.data.message : "Error!!",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }
  };

  // Function to fetch event data
  const getEvent = useCallback(async () => {
    try {
      const response = await Services.getEvent(eventId);
      const event = response.data;

      setEventData({
        ...event,
        metaData: event.metaData.join(","),
        metaTitle: event.metaTitle.join(","),
        eventModes: event.eventModes._id,
        eventType: event.eventType._id,
      });

      if (event.groups.overview && event.groups.overview.length > 0) {
        setOverviewDetailsData(event.groups.overview);
      } else {
        setOverviewDetailsData([]);
      }

      if (event.groups.keyTracks && event.groups.keyTracks.length > 0) {
        SetKeyTracksData(event.groups.keyTracks);
      } else {
        SetKeyTracksData([]);
      }

      if (event.groups.agenda && event.groups.agenda.length > 0) {
        setAgendaDetailsData(event.groups.agenda);
      } else {
        setAgendaDetailsData([]);
      }

      if (event.groups.faqs && event.groups.faqs.length > 0) {
        setFaqsDetailsData(event.groups.faqs);
      } else {
        setFaqsDetailsData([]);
      }

      if (event.groups.partners && event.groups.partners.length > 0) {
        setPartnersDetailsData(event.groups.partners);
      } else {
        setPartnersDetailsData([]);
      }

      if (event.groups.contactUs && event.groups.contactUs.length > 0) {
        setContactUsDetailsData(event.groups.contactUs);
      } else {
        setContactUsDetailsData([]);
      }

      if (event.groups.KeyDiscussion && event.groups.KeyDiscussion.length > 0) {
        setDiscussionPointDetailsData(event.groups.KeyDiscussion);
      } else {
        setDiscussionPointDetailsData([]);
      }

      // if (event.groups.keyHighlights && event.groups.keyHighlights.length > 0) {
      //   setKeyHighlightDetailsData(event.groups.keyHighlights[0]);
      // } else {
      //   setKeyHighlightDetailsData(null);
      // }

      if (event.groups.registration && event.groups.registration.length > 0) {
        setRegistrationDetailsData(event.groups.registration);
      } else {
        setRegistrationDetailsData([]);
      }

      if (event.groups.speakers && event.groups.speakers.length > 0) {
        setSpeakerDetailsData(event.groups.speakers);
      } else {
        setSpeakerDetailsData([]);
      }

      if (
        event.groups.awards &&
        event.groups.awards.length > 0
      ) {
        setAwardDetailsData(event.groups.awards);
      } else {
        setAwardDetailsData([]);
      }

      if (
        event.groups.whyParticipate &&
        event.groups.whyParticipate.length > 0
      ) {
        setWhyParticipateDetailsData(event.groups.whyParticipate);
      } else {
        setWhyParticipateDetailsData([]);
      }

      if (event.groups.previousEvent && event.groups.previousEvent.length > 0) {
        SetEventPreviousData(event.groups.previousEvent);
      } else {
        SetEventPreviousData([]);
      }

      if (event.groups.eventGallery && event.groups.eventGallery.length > 0) {
        SetEventGalleryData(event.groups.eventGallery);
      } else {
        SetEventGalleryData([]);
      }

      if (event.groups.miscellaneous && event.groups.miscellaneous.length > 0) {
        setMiscellaneousDetailsData(event.groups.miscellaneous[0]);
      } else {
        setMiscellaneousDetailsData(null);
      }

      if (event.groups.venue) {
        setVenueDetailsData(event.groups.venue);
      } else {
        setVenueDetailsData(null);
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : "Error!!",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }
  }, [eventId]);

  // Fetch event data, modes, and types on component mount
  useEffect(() => {
    getEvent();
    getEventModes();
    getEventTypes();
  }, [getEvent]);

  // Function to handle form submission
  const onSubmit = async (event) => {
    event.preventDefault();

    let startDate = "";
    if (eventData.startDate)
      // console.log("eventData.startDate : ", eventData.startDate);
      startDate = new Date(eventData.startDate).toISOString();
    let endDate = "";
    if (eventData.endDate) endDate = new Date(eventData.endDate).toISOString();
    // console.log("keyTracksData : ", keyTracksData);
    const allNewData = {
      groups: {
        keyTracks: keyTracksData,
        registration: registrationDetailsData,
        overview: overviewDetailsData,
        partners: partnersDetailsData,
        // keyHighlights: [keyHighlightDetailsData],
        KeyDiscussion: discussionPointDetailsData,
        whyParticipate: whyParticipateDetailsData,
        awards: awardDetailsData,
        agenda: agendaDetailsData,
        speakers: speakerDetailsData,
        faqs: faqsDetailsData,
        previousEvent: eventPreviousData,
        contactUs: contactUsDetailsData,
        venue: venueDetailsData,
        eventGallery: eventGalleryData,
        miscellaneous: miscellaneousDetailsData,
      },
    };
    const additionalData = {
      metaData: eventData.metaData.split(",").map((item) => item.trim()),
      metaTitle: eventData.metaTitle.split(",").map((item) => item.trim()),
      startDate: startDate,
      endDate: endDate,
    };
    // console.log("allNewData : ", {
    //   ...eventData,
    //   ...additionalData,
    //   ...allNewData,
    // });

    try {
      await Services.updateEvent(eventId, {
        ...eventData,
        ...additionalData,
        ...allNewData,
      });
      toast.success("Event Updated Successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      console.log("Error:", error);
      for (let message of error.response.data) {
        toast.error(message.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      console.log("error", error);
    }
  };

  // Function to handle input changes
  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (files) {
      return setEventData((prevValue) => ({
        ...prevValue,
        [name]: files[0],
      }));
    }

    if (name === "paidEntry" || name === "pinned" || name === "publish") {
      setEventData((prevValue) => ({
        ...prevValue,
        [name]: value === "true",
      }));
    } else {
      setEventData((prevValue) => ({
        ...prevValue,
        [name]: value,
      }));
    }
  };

  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1>{title}</h1>
        </div>
        {eventModes && eventTypes ? (
          <div className="bottom">
            <div className="right">
              <form onSubmit={onSubmit}>
                {inputs.map((input) => (
                  <div className="formInput" key={input.id}>
                    <label>
                      {input.label}
                      {input.required && "*"}
                    </label>
                    {input.name === "pinned" || input.name === "publish" ? (
                      <select
                        value={eventData[input.name]}
                        name={input.name}
                        onChange={handleChange}
                      >
                        <option value={true}>True</option>
                        <option value={false}>False</option>
                      </select>
                    ) : input.name === "eventModes" ? (
                      <select
                        value={eventData[input.name]}
                        name={input.name}
                        // defaultValue={"default"}
                        onChange={handleChange}
                      >
                        <option value={"default"} disabled={true}>
                          Choose an option
                        </option>
                        {eventModes.map((mode) => {
                          return (
                            <option key={mode._id} value={mode._id}>
                              {mode.mode}
                            </option>
                          );
                        })}
                      </select>
                    ) : input.type === "quill" ? (
                      <>
                        <RichTextEditor
                          name={input.name}
                          onChange={handleChange}
                          value={eventData[input.name]}
                        />
                      </>
                    ) : input.name === "eventType" ? (
                      <select
                        value={eventData[input.name]}
                        name={input.name}
                        // defaultValue={"default"}
                        onChange={handleChange}
                      >
                        <option value={"default"} disabled={true}>
                          Choose an option
                        </option>
                        {eventTypes.map((type) => {
                          return (
                            <option key={type._id} value={type._id}>
                              {type.type}
                            </option>
                          );
                        })}
                      </select>
                    ) : input.name === "recurringTag" ? (
                      <select
                        value={eventData[input.name]}
                        name={input.name}
                        onChange={handleChange}
                      >
                        <option value="None">None</option>
                        <option value="Daily">Daily</option>
                        <option value="Weekly">Weekly</option>
                        <option value="Monthly">Monthly</option>
                        <option value="Yearly">Yearly</option>
                      </select>
                    ) : input.name === "fontFamily" ? (
                      <select
                        value={eventData[input.name]}
                        name={input.name}
                        onChange={handleChange}
                      >
                        <option value={"default"} disabled={false}>
                          Choose an option
                        </option>
                        <option value="Montserrat" style={{ fontFamily: "Montserrat, sans-serif" }}>Montserrat</option>
                        <option value="Roboto" style={{ fontFamily: "Roboto, sans-serif" }}>Roboto</option>
                        <option value="Poppins" style={{ fontFamily: "Poppins, sans-serif" }}>Poppins</option>
                        <option value="Gulory" style={{ fontFamily: "Gulory, sans-serif" }}>Gulory</option>
                        <option value="Gotham" style={{ fontFamily: "Gotham, sans-serif" }}>Gotham</option>
                        <option value="Raleway" style={{ fontFamily: "Raleway, sans-serif" }}>Raleway</option>
                        <option value="Open Sans" style={{ fontFamily: "Open Sans, sans-serif" }}>Open Sans</option>
                        <option value="Cabin" style={{ fontFamily: "Cabin, sans-serif" }}>Cabin</option>
                        <option value="Futura" style={{ fontFamily: "Futura, sans-serif" }}>Futura</option>
                        <option value="Sitka" style={{ fontFamily: "Sitka, sans-serif" }}>Sitka</option>
                        <option value="Arizona" style={{ fontFamily: "Arizona, sans-serif" }}>Arizona</option>
                        <option value="Atures" style={{ fontFamily: "Atures, sans-serif" }}>Atures</option>
                        <option value="Bauhaus 93" style={{ fontFamily: "Bauhaus 93, sans-serif" }}>Bauhaus 93</option>
                        <option value="Berlin Sans" style={{ fontFamily: "Berlin Sans, sans-serif" }}>Berlin Sans</option>
                        <option value="Bodoni, serif" style={{ fontFamily: "Bodoni, serif" }}>Bodoni, serif</option>
                        <option value="Century" style={{ fontFamily: "Century, sans-serif" }}>Century</option>
                        <option value="Cooper black" style={{ fontFamily: "Cooper black, sans-serif" }}>Cooper black</option>
                        <option value="Eras" style={{ fontFamily: "Eras, sans-serif" }}>Eras</option>
                        <option value="Franklin" style={{ fontFamily: "Franklin, sans-serif" }}>Franklin</option>
                        <option value="Fugaz one" style={{ fontFamily: "Fugaz one, sans-serif" }}>Fugaz one</option>
                        <option value="Gill Sans" style={{ fontFamily: "Gill Sans, sans-serif" }}>Gill Sans</option>
                        <option value="Lato" style={{ fontFamily: "Lato, sans-serif" }}>Lato</option>
                        <option value="Muli" style={{ fontFamily: "Muli, sans-serif" }}>Muli</option>
                        <option value="Lord" style={{ fontFamily: "Lord, sans-serif" }}>Lord</option>
                        <option value="Nexa" style={{ fontFamily: "Nexa, sans-serif" }}>Nexa</option>
                        <option value="Oswald" style={{ fontFamily: "Oswald, sans-serif" }}>Oswald</option>
                        <option value="Rubi" style={{ fontFamily: "Rubi, sans-serif" }}>Rubi</option>
                      </select>
                    ) : input.name === "eventBannerFileType" ? (
                      <select
                        value={eventData[input.name]}
                        name={input.name}
                        onChange={handleChange}
                      >
                        <option value="image">image</option>
                        <option value="gif">gif</option>
                        <option value="video">video</option>
                      </select>
                    ) : (
                      <>
                        {/* {input.type === "file" ? <input
                          type={input.type}
                          name={input.name}
                          placeholder={input.placeholder}
                          onChange={handleChange}
                        /> : <input
                          type={input.type}
                          name={input.name}
                          value={eventData[input.name]}
                          placeholder={input.placeholder}
                          onChange={handleChange}
                        />} */}
                        {input.type === "file" ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <input
                              type={input.type}
                              name={input.name}
                              onChange={handleChange}
                            />
                            <img
                              style={{
                                width: "40%",
                                height: "80px",
                                objectFit: "cover",
                                borderRadius: "8px",
                                marginTop: "10px",
                                marginLeft: "10px",
                                maxWidth: "100px",
                              }}
                              src={eventData[input.name]}
                              alt=""
                            />
                          </div>
                        ) : input.type === "color" ? (
                          <div className="mt-4 flex items-center w-full">
                            <input
                              type={input.type}
                              name={input.name}
                              value={eventData[input.name]}
                              placeholder={input.placeholder}
                              onChange={handleChange}
                              style={{
                                width: "55px",
                                height: "55px",
                                padding: "5px",
                              }}
                            />
                            <input
                              type="text"
                              name={input.name}
                              value={eventData[input.name]}
                              placeholder={input.placeholder}
                              onChange={handleChange}
                            />
                          </div>
                        ) : input.type === "datetime-local" ? (
                          <input
                            type={input.type}
                            name={input.name}
                            value={
                              eventData[input.name]
                                ? new Date(eventData[input.name])
                                  .toISOString()
                                  .slice(0, 16)
                                : ""
                            }
                            placeholder={input.placeholder}
                            onChange={handleChange}
                          />
                        ) : (
                          <input
                            type={input.type}
                            name={input.name}
                            value={eventData[input.name]}
                            placeholder={input.placeholder}
                            onChange={handleChange}
                          />
                        )}
                      </>
                    )}
                  </div>
                ))}
                {/* yogesh */}

                <div className="p-8">
                  <div className="flex space-x-10">
                    <div className="flex-1">
                      <label className="mb-6 text-l font-medium text-[#489FC5]">
                        Primary Color
                      </label>
                      <div className="flex items-center">
                        <input
                          type="color"
                          name="primaryColor"
                          value={eventData.primaryColor}
                          placeholder={"#ee"}
                          onChange={handleChange}
                          style={{
                            width: "55px",
                            height: "55px",
                            padding: "5px",
                            marginRight: "10px",
                          }}
                        />
                        <input
                          type="text"
                          name="primaryColor"
                          value={eventData.primaryColor}
                          placeholder={"#ee"}
                          onChange={handleChange}
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-l rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-4 w-full"
                        />
                      </div>
                    </div>

                    <div className="flex-1">
                      <label className="mb-6 text-l font-medium text-[#489FC5]">
                        Secondary Color
                      </label>
                      <div className="flex items-center">
                        <input
                          type="color"
                          name="secondaryColor"
                          value={eventData.secondaryColor}
                          placeholder={"#ee"}
                          onChange={handleChange}
                          style={{
                            width: "55px",
                            height: "55px",
                            padding: "5px",
                            marginRight: "10px",
                          }}
                        />
                        <input
                          type="text"
                          name="secondaryColor"
                          value={eventData.secondaryColor}
                          placeholder={"#ee"}
                          onChange={handleChange}
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-l rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-4 w-full"
                        />
                      </div>
                    </div>

                    <div className="flex-1">
                      <label className="mb-6 text-l font-medium text-[#489FC5]">
                        Tertiary Color
                      </label>
                      <div className="flex items-center">
                        <input
                          type="color"
                          name="tertiaryColor"
                          value={eventData.tertiaryColor}
                          placeholder={"#ee"}
                          onChange={handleChange}
                          style={{
                            width: "55px",
                            height: "55px",
                            padding: "5px",
                            marginRight: "10px",
                          }}
                        />
                        <input
                          type="text"
                          name="tertiaryColor"
                          value={eventData.tertiaryColor}
                          placeholder={"#ee"}
                          onChange={handleChange}
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-l rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-4 w-full "
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex space-x-10">
                    <div className="flex-1">
                      <label className="mb-6 text-l font-medium text-[#489FC5]">
                        Fourth Color
                      </label>
                      <div className="flex items-center">
                        <input
                          type="color"
                          name="fourthColor"
                          value={eventData.fourthColor}
                          placeholder={"#ee"}
                          onChange={handleChange}
                          style={{
                            width: "55px",
                            height: "55px",
                            padding: "5px",
                            marginRight: "10px"
                          }}
                        />
                        <input
                          type="text"
                          name="fourthColor"
                          value={eventData.fourthColor}
                          placeholder={"#ee"}
                          onChange={handleChange}
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-l rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-4 w-full"
                        />
                      </div>
                    </div>

                    <div className="flex-1">
                      <label className="mb-6 text-l font-medium text-[#489FC5]">
                        Fifth Color
                      </label>
                      <div className="flex items-center">
                        <input
                          type="color"
                          name="fifthColor"
                          value={eventData.fifthColor}
                          placeholder={"#ee"}
                          onChange={handleChange}
                          style={{
                            width: "55px",
                            height: "55px",
                            padding: "5px",
                            marginRight: "10px"
                          }}
                        />
                        <input
                          type="text"
                          name="fifthColor"
                          value={eventData.fifthColor}
                          placeholder={"#ee"}
                          onChange={handleChange}
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-l rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-4 w-full"
                        />
                      </div>
                    </div>

                    <div className="flex-1">
                      <label className="mb-6 text-l font-medium text-[#489FC5]">
                        Sixth Color
                      </label>
                      <div className="flex items-center">
                        <input
                          type="color"
                          name="sixthColor"
                          value={eventData.sixthColor}
                          placeholder={"#ee"}
                          onChange={handleChange}
                          style={{
                            width: "55px",
                            height: "55px",
                            padding: "5px",
                            marginRight: "10px"
                          }}
                        />
                        <input
                          type="text"
                          name="sixthColor"
                          value={eventData.sixthColor}
                          placeholder={"#ee"}
                          onChange={handleChange}
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-l rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-4 w-full "
                        />
                      </div>
                    </div>
                  </div>

                  <div className="flex w-full flex-col">
                    <label className=" mb-2  mt-6 text-l   font-medium text-[#489FC5]">
                      Page Description
                    </label>

                    <textarea
                      name="pageDescription"
                      value={eventData.pageDescription}
                      placeholder="Page Description"
                      onChange={handleChange}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-l rounded-lg focus:ring-blue-500 focus:border-[#489FC5] block p-5 w-600"
                      rows="5" // Increased number of lines
                    />
                  </div>
                </div>
                <div className="flex w-full flex-col">
                  <Overview
                    overviewDetailsData={overviewDetailsData}
                    setOverviewDetailsData={setOverviewDetailsData}
                  />
                  <KeyTracks
                    keyTracksData={keyTracksData}
                    SetKeyTracksData={SetKeyTracksData}
                  />
                  <Partners
                    partnersDetailsData={partnersDetailsData}
                    setPartnersDetailsData={setPartnersDetailsData}
                  />
                  <Agenda
                    agendaDetailsData={agendaDetailsData}
                    setAgendaDetailsData={setAgendaDetailsData}
                  />{" "}
                  <WhyParticipate
                    whyParticipateDetailsData={whyParticipateDetailsData}
                    setWhyParticipateDetailsData={setWhyParticipateDetailsData}
                  />
                  <Awards awardDetailsData={awardDetailsData} 
                  setAwardDetailsData={setAwardDetailsData} />

                  <Speaker
                    speakerDetailsData={speakerDetailsData}
                    setSpeakerDetailsData={setSpeakerDetailsData}
                  />

                  <Registration
                    registrationDetailsData={registrationDetailsData}
                    setRegistrationDetailsData={setRegistrationDetailsData}
                  />
                  
                  <Faqs
                    faqsDetailsData={faqsDetailsData}
                    setFaqsDetailsData={setFaqsDetailsData}
                  />
                  {/* <KeyHighlight
                    keyHighlightDetailsData={keyHighlightDetailsData}
                    setKeyHighlightDetailsData={setKeyHighlightDetailsData}
                  /> */}
                  <DiscussionPoints
                    discussionPointDetailsData={discussionPointDetailsData}
                    setDiscussionPointDetailsData={
                      setDiscussionPointDetailsData
                    }
                  />
                  <PreviousEvent
                    eventPreviousData={eventPreviousData}
                    SetEventPreviousData={SetEventPreviousData}
                  />
                  <EventGallery
                    eventGalleryData={eventGalleryData}
                    SetEventGalleryData={SetEventGalleryData}
                  />
                  <ContactUs
                    contactUsDetailsData={contactUsDetailsData}
                    setContactUsDetailsData={setContactUsDetailsData}
                  />
                  <Venue
                    venueDetailsData={venueDetailsData}
                    setVenueDetailsData={setVenueDetailsData}
                  />
                  <MiscellaneousEvent
                    miscellaneousDetailsData={miscellaneousDetailsData}
                    setMiscellaneousDetailsData={setMiscellaneousDetailsData}
                  />
                </div>

                <button className="py-2 px-6 rounded-md text-xl bg-[#489FC5] text-white mx-auto">
                  Send
                </button>
              </form>
            </div>
          </div>
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
};

export default UpdateEventPage;
