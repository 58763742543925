import "../singlepage.scss";
import { useParams } from "react-router-dom";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useEffect } from "react";
import { Services } from "../../Services";
import { useState } from "react";
import Loader from "../../components/loader/Loader";

import { toast } from "react-toastify";
import { Link } from "react-router-dom";

const EventPage = () => {
  const { eventId } = useParams();
  const [eventData, setEventData] = useState(null);

  useEffect(() => {
    const getEvent = async () => {
      try {
        const response = await Services.getEvent(eventId);
        setEventData(response.data);
        // console.log(response.data);
        toast.success("Event fetched successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
    
      } catch (error) {
        toast.error(
          error.response.data.message
            ? error.response.data.message
            : "Error!! from getEvent",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      }
    };

    getEvent();
  }, [eventId]);

  return (
    <div className="single">
      <Sidebar />
      {eventData ? (
        <div className="singleContainer">
          <Navbar />
          <div className="top">
            <div className="left">
              <Link
                to={`/events/update/${eventId}`}
                style={{ textDecoration: "none" }}
              >
                <div className="editButton">Edit</div>
              </Link>
              <h1 className="title">Information</h1>
              <div className="item">
                <div className="details">
                  <h1 className="itemTitle">{eventData.eventName}</h1>
                  <div className="detailItem">
                    <span className="itemKey">Event Id:</span>
                    <span className="itemValue">{eventData.eventId}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Event Description:</span>
                    <span className="itemValue">
                      {eventData.pageDescription}
                    </span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Modes:</span>
                    <span className="itemValue">
                      {eventData.eventModes.mode}
                    </span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Event Type:</span>
                    <span className="itemValue">
                      {eventData.eventType.type}
                    </span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Event Banner File Type:</span>
                    <span className="itemValue">
                      {eventData.eventBannerFileType}
                    </span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Event Banner:</span>
                    <span className="itemValue">
                      {eventData.eventBannerImage}
                    </span>
                  </div>

                  <div className="detailItem">
                    <span className="itemKey">Start Date:</span>
                    <span className="itemValue">
                      {new Date(eventData.startDate).toLocaleString("en-GB", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: true, // This will specify AM or PM
                      })}
                    </span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">End Date:</span>
                    <span className="itemValue">
                      {new Date(eventData.endDate).toLocaleString("en-GB", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: true, // This will specify AM or PM
                      })}
                    </span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Meta Title:</span>
                    <span className="itemValue">{eventData.metaTitle}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Meta Data:</span>
                    <span className="itemValue">{eventData.metaData}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Publish:</span>
                    <span className="itemValue">{`${eventData.publish}`}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Pinned:</span>
                    <span className="itemValue">{`${eventData.pinned}`}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Primary Color:</span>
                    <span className="itemValue">{eventData.primaryColor}</span>
                  </div>

                  <div className="detailItem">
                    <span className="itemKey">Secondry Color:</span>
                    <span className="itemValue">
                      {eventData.secondaryColor}
                    </span>
                  </div>

                  <div className="detailItem">
                    <span className="itemKey">Tertiary Color:</span>
                    <span className="itemValue">
                      {`${eventData.tertiaryColor}`}
                    </span>
                  </div>

                  <div className="detailItem">
                    <span className="itemKey">Recuring Tag:</span>
                    <span className="itemValue">{eventData.recurringTag}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Template Number:</span>
                    <span className="itemValue">{eventData.templateNo}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default EventPage;
